/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from 'react';
import {useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Text,
  View,
  Image, 
  StyleSheet,   
  Platform,
  TouchableOpacity,
} from 'react-native';
import {environment} from './../environments/environment.prod';
import { 
  TextInput, 
  Card,
  Title, 
  Button,
  HelperText, 
  useTheme, 
} from 'react-native-paper';


const RetrieveUsername = ({navigation}) => {
    
  const {
    colors: { accent, surface },
  } = useTheme();


  const [email, setEmail] = useState('');
  const [genericError, setGenericError] = useState('');
  const [success, setSuccess] = useState('');

  const getUsername = async () => {
    try {
      const response = await fetch(
        environment.ApiURL +
          '/ParticipantPortal/retrieveusername?email=' + email,
      ).then((response) => response.json())
      .then((data) => {
        if (data.Success) {
          setGenericError("");
          setSuccess(data.Message);
        } else {
          setSuccess("");
          setGenericError(data.Message);
        }
      })
      .catch((error) => {
        setSuccess("");
        setGenericError(error);
      });
    // eslint-disable-next-line no-empty
  } finally {
  }
};

  const navigateToPage = (page: string) => {
    navigation.navigate(page);
  };

  return (
    <View>
      <Card style={styles.card}>
        <View style={styles.logo}>
        {Platform.OS === 'web' ? (
          <TouchableOpacity activeOpacity = { .5 } onPress={() => navigateToPage('Sign In')}>
          <Image
            style={styles.image}
            source={require('../assets/logo.png')}
          />
          </TouchableOpacity>
            ) : (
              <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
            )}
        </View>
        <Card.Content>
          <Title style={styles.title}>Retrieve Username</Title>
        </Card.Content> 
        <View style={styles.container}>     
          <TextInput
            textAlign="left"
            mode="outlined"
            theme={{colors: {text: 'white', primary: accent, background: surface}}}
            style={styles.inputContainerStyle}
            label="Enter Email"
            value={email}
            onChangeText={(text) => setEmail(text)}
            error={genericError.length != 0}  
          />   
          <HelperText
            style={styles.error}
            type="error" visible={genericError.length != 0}>
            {genericError}
          </HelperText>
         <Button
            style={styles.buttonAlt}
            mode="contained"
            onPress={() => getUsername()}>
            <Text>Get Username</Text>
          </Button>       
          <HelperText
            style={styles.success}
            type="error" visible={success.length != 0}>
            {success}
          </HelperText>
          <Button  
            style={styles.forgot}        
            mode="contained"
            onPress={() => navigateToPage('Trouble Signing In')}
            icon="chevron-left">
            <Text>Back</Text>
          </Button>    
        </View>
      </Card>     
    </View>
  );
};

const styles = StyleSheet.create({
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: "5%",
  },
  card: {
    justifyContent: 'center',
    borderRadius: 30,
    height: "auto",
    width: "100%",
    minWidth: 350,
    maxWidth: 500,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 30,
    padding: "5%",
  },
  container: {
    padding: 8,
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  success: {
    fontSize: 14,
    color: '#c9deea',
    paddingLeft: 25,
    paddingTop: 4,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 15,
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  logo:{
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: "relative",
    margin: 'auto',
  },
  image:{
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: "15%",
  },
  buttonAlt: {
    margin: 8,
    padding: 10,
    marginTop: 10,
    backgroundColor: '#f1955b',
  },
});

export default RetrieveUsername;
