/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Text,
  View,
  StyleSheet,
  Image,
  SafeAreaView,
  Alert,
  Linking,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {environment} from './../environments/environment.prod';
import {
  TextInput,
  Card,
  Title,
  Paragraph,
  Button,
  useTheme,
} from 'react-native-paper';

//-999999 used for Not a blood donor
//-1 remind me later/not entered
//other values mean they have verified and this page does not need to be loaded
const ConnectDonnorID = ({route, navigation}) => {
  const {url} = route.params;
  const theme = useTheme();

  /**
   * Gets user donor ID form initla URL
   * @returns donorId
   */
  function getDonorId(): string {
    console.log('URL is ', url);
    const myUrl = url.toLowerCase();
    const location = myUrl.search('/donorid/');
    if (location === -1 || !location) {
      console.log('Result is  ', -99);
      return '';
    }
    const donorId = myUrl.substring(location, url.length).split('/');

    console.log('Found Result is  ', donorId[2]);
    return donorId[2];
  }
  const {token, userProfile, org} = route.params;
  const [id, setId] = useState(getDonorId());
  const [genericError, setGenericError] = useState('');
  const navigateToPage = (page: string) => {
    // console.log(token);
    // console.log(userProfile);
    navigation.navigate(page, {token: token, userProfile: userProfile});
  };

  const gotoVerificationURL = () => {
    console.log(org);
    if (Platform.OS === 'web') {
      window.open(org.VerificationURL, '_blank');
    } else {
      Linking.canOpenURL(org.VerificationURL).then((supported) => {
        return Linking.openURL(org.VerificationURL);
      });
    }
  };

  //todo
  const verify = async (value: string) => {
    const json: string =
      '{"UserId": "' +
      userProfile.UserId +
      '" , "UserIdentity": "' +
      value +
      '"}';
    console.log(json);
    try {
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/updateuseridentity',
        {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: json,
        },
      )
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {
          setGenericError(error);
        });
    } finally {
      navigation.navigate('Medical Entities', {
        token: token,
        userProfile: userProfile,
      });
    }
  };

  return (
    <View style={styles.bgcontainer}>
      <Card style={styles.card}>
        <View style={styles.logo}>
          {Platform.OS === 'web' ? (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => navigateToPage('Medical Entities')}>
              <Image
                style={styles.image}
                source={require('../assets/logo.png')}
              />
            </TouchableOpacity>
          ) : (
            <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
          )}
        </View>
        <Card.Content>
          <Title style={styles.title}>
            YOU HAVE NOT VERIFIED YOUR DONOR ID
          </Title>
        </Card.Content>
        <View
          style={[
            styles.container,
            {backgroundColor: theme.colors.background},
          ]}>
          <View>
            <Paragraph
              style={{
                color: '#c9deea',
                textAlign: 'center',
                fontSize: 16,
              }}>
              If you don't have your donor ID select{' '}
            </Paragraph>
            <Text
              style={{
                color: theme.colors.accent,
                fontSize: 16,
                textAlign: 'center',
                paddingBottom: 15,
              }}
              onPress={() => gotoVerificationURL()}>
              Request Donor ID.{'\n'}
            </Text>
          </View>
          <View style={styles.donorId}>
            <TextInput
              textAlign="left"
              mode="outlined"
              theme={{
                colors: {
                  text: 'white',
                  primary: theme.colors.accent,
                  background: theme.colors.surface,
                },
              }}
              value={id}
              style={styles.inputContainerStyle}
              label="Donor ID"
              onChangeText={(text) => setId(text)}
            />
          </View>
          <View style={styles.buttons}>
            <Button
              style={[styles.button, {backgroundColor: theme.colors.accent}]}
              mode="contained"
              onPress={() => verify(id)}>
              <Text>Verify</Text>
            </Button>
            <Button
              mode="contained"
              style={[
                styles.button,
                {text: 'white', backgroundColor: theme.colors.primary},
              ]}
              onPress={() => verify('-999999')}>
              <Text>Not A Blood Donor</Text>
            </Button>
            <Button
              style={styles.forgot}
              mode="contained"
              onPress={() => navigateToPage('Medical Entities')}>
              <Text>Remind Me Later</Text>
            </Button>
          </View>
          {/* <View>
          <TextInput value={genericError} />
        </View> */}
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  donorId: {
    flexDirection: 'column',
    paddingHorizontal: 20,
  },
  title: {
    textAlign: 'center',
    marginTop: '5%',
  },
  card: {
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 30,
    height: 'auto',
    width: '80%',
    minWidth: 350,
    maxWidth: 600,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 30,
    padding: '5%',
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    alignItems: 'center',
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
  },
  buttons: {
    flexDirection: 'column',
    // alignItems: 'center',
    paddingHorizontal: 20,
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    fontWeight: '400',
    textTransform: 'capitalize',
    color: 'white',
    marginTop: 20,
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: '15%',
  },
});

export default ConnectDonnorID;
