import {StyleSheet, Platform} from 'react-native';

const Styles = () =>
  StyleSheet.create({
    temp: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    notificationCircle: {
      marginLeft: '18px',
      marginTop: '4px',
      width: 14,
      height: 14,
      borderRadius: 7,
      backgroundColor: 'red',
      position: 'absolute',
    },
    notificationCount: {
      color: 'rgb(241, 149, 91)',
      textAlign: 'center',
      fontSize: 10,
    },
    orgLogo: {
      backgroundColor: 'white',
      marginBottom: 10,
      zIndex: 999999,
      alignItems: 'center',
      justifyContent: 'center',
      width: 300,
      height: 100,
    },
    divider: {
      flex: 1,
      borderRadius: 0,
      backgroundColor: '#0a638c',
      height: 12,
    },
    title: {
      textAlign: 'center',
      marginTop: '2%',
      color: '#0f153a',
      fontWeight: '700',
    },
    titleAlt: {
      textAlign: 'center',
      fontSize: 30,
      color: '#0f153a',
      fontWeight: '700',
    },
    titleNL: {
      margin: '5%',
      textAlign: 'center',
    },
    signup: {
      marginHorizontal: '10%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: 'auto',
    },
    space: {
      height: 30,
    },
    newsletter: {
      backgroundColor: '#0a638c',
    },
    tagline: {
      textAlign: 'center',
      marginVertical: '5%',
    },
    inputContainerStyle: {
      margin: 8,
      display: 'flex',
      flexGrow: 1,
    },
    inputContainerStyle2: {
      margin: 8,
      display: 'flex',
      flexGrow: 2,
    },
    underline: {textDecorationLine: 'underline'},
    progress: {
      minWidth: '100%',
      minHeight: 'auto',
      height: 15,
      zIndex: 999,
      backgroundColor: '#c9deea',
      // borderTopLeftRadius: 45,
      borderBottomLeftRadius: 60,
      // borderTopRightRadius: 45,
      borderBottomRightRadius: 60,
    },
    footer: {
      backgroundColor: '#c9deea',
    },
    buttonNL: {
      margin: 8,
      flexGrow: 1,
      padding: 10,
      marginTop: 10,
      backgroundColor: '#0f153a',
      justifyContent: 'center',
      maxWidth: 150,
    },
    surface: {
      display: 'flex',
      height: 'auto',
      width: 295,
      // width:'auto',
      alignItems: 'center',
      justifyContent: 'center',
      // elevation: 4,
      backgroundColor: '#0a638c',
      margin: 15,
      //borderRadius: 45,
      borderTopLeftRadius: 25,
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 25,
      borderBottomRightRadius: 15,
      overflow: 'visible',
    },
    // text: #151b40
    surfaceAlt: {
      display: 'flex',
      height: 500,
      width: 220,
      // width:'auto',
      alignItems: 'center',
      justifyContent: 'center',
      // elevation: 4,
      backgroundColor: '#e2e7eb',
      margin: 15,
      borderRadius: 25,
      // borderTopLeftRadius: 25,
      // borderBottomLeftRadius: 15,
      // borderTopRightRadius: 25,
      // borderBottomRightRadius: 15,
      overflow: 'visible',
    },
    surfaceAlt2: {
      display: 'flex',
      // height: 400,
      // width: 220,
      // width:'auto',
      alignItems: 'center',
      justifyContent: 'center',
      // elevation: 4,
      backgroundColor: '#e2e7eb',
      margin: 50,
      borderRadius: 25,
      // borderTopLeftRadius: 25,
      // borderBottomLeftRadius: 15,
      // borderTopRightRadius: 25,
      // borderBottomRightRadius: 15,
      overflow: 'visible',
    },
    titleAlt2: {
      // position: "relative",

      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      textAlign: 'center',
      height: 'auto',
      width: 170,
      //marginVertical: 'auto',
      marginHorizontal: '7%',
      color: '#11173c',
      fontSize: 15,
      fontWeight: 'bold',
      // fontFamily: 'lucida grande'
      marginTop: 15,
      marginBottom: 'auto',
    },
    descriptionAlt: {
      // position: "relative",
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      textAlign: 'center',
      height: 'auto',
      //width: 170,
      // marginVertical: 'auto',
      marginHorizontal: '7%',
      color: '#11173c',
      fontSize: 14,
      

      // padding: 25,
      //fontWeight: 'bold',
      // fontFamily: 'lucida grande'
      // marginTop: 'auto',
      // marginBottom: 'auto',
    },
    category: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 8,
      padding: '5%',
    },
    card: {
      flex: 1,
      justifyContent: 'center',
      maxHeight: '100%',
      width: '100%',
      margin: 'auto',
      paddingTop: 10,
      borderRadius: 0,
      backgroundColor: '#0f153a',
    },
    header: {
      //flex: 1,
      //flexDirection: 'column',
      justifyContent: 'center',
      // flexWrap: 'warp',
      maxHeight: '100%',
      width: '100%',
      margin: 'auto',
      paddingTop: 10,
      borderRadius: 0,
      backgroundColor: '#c9deea',
    },
    line: {
      margin: 0,
      height: 12,
      backgroundColor: '#0a638c',
    },
    line2: {
      margin: 0,
      height: 12,
      backgroundColor: '#0f153a',
    },

    line3: {
      margin: 0,
      height: 12,
      backgroundColor: '#0a638c',
    },
    cardAlt: {
      flex: 1,
      justifyContent: 'center',
      maxHeight: '100%',
      width: '100%',
      borderRadius: 0,
    },
    container: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    paragraph: {
      color: '#0f153a',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: 16,
      padding: 20,
      maxWidth: '100%',
      margin: 'auto',
    },
    paragraphAlt: {
      color: '#c9deea',
      textAlign: 'center',
      fontSize: 16,
      padding: 10,
      maxWidth: '100%',
      margin: 'auto',
    },
    entity: {
      // position: "relative",

      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      textAlign: 'center',
      height: 'auto',
      width: 170,
      marginVertical: 'auto',
      marginHorizontal: '7%',
      color: '#FFFFFF',
      fontSize: 20,
      fontWeight: 'bold',
      // fontFamily: 'lucida grande'
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    button: {
      margin: 8,
      padding: 10,
      marginTop: 10,
    },
    buttonAlt: {
      //flex: 1,
      //flexWrap: 'warp',
      width: 300,
      margin: 8,
      padding: 10,
      backgroundColor: '#f1955b',
    },
    buttonAlt2: {
      marginTop: 15,
      marginBottom: 30,
      margin: 8,
      padding: 5,
      backgroundColor: '#0a638c',
    },
    buttonAlt3: {
      marginTop: 'auto',
      marginBottom: 30,
      margin: 8,
      borderRadius: 10,
      //padding: 1,
      backgroundColor: '#0a638c',
    },
    opportunityImage: {
      marginTop: 10,
      // marginBottom: 30,
      // margin: 8,
      // borderRadius: 10,
      // //padding: 1,
      // backgroundColor: '#0a638c',
    },
    opportunityButtonContainer: {
      marginTop: 'auto',
      // marginBottom: 30,
      margin: 8,
      borderRadius: 10,
      //padding: 1,
      // backgroundColor: '#0a638c',
    },
    opportunityButton: {
      marginTop: 30,
      marginBottom: 30,
      margin: 8,
      borderRadius: 10,
      //padding: 1,
      backgroundColor: '#0a638c',
    },
    align: {
      // display: 'flex',
      flexFlow: 'row',
      flexWrap: 'wrap',
      // flexWrap: 'warp',
      // display: 'flex',
      margin: 'auto',
      marginBottom: 5,
      paddingHorizontal: 40,
    },
    FAQ: {
      color: '#0a638c',
      paddingHorizontal: 40,
    },
    forgot: {
      backgroundColor: 'transparent',
      alignItems: 'flex-end',
      marginLeft: 15,
      fontWeight: '400',
    },
    navbar: {
      flexDirection: 'row',
      display: 'flex',
      flex: 1,
    },
    navbarIcon: {
      marginLeft: 'auto',
      marginTop: '5px',
      marginRight: '5px',
    },
    navbarContent: {
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 255, 0.87)',
    },
    medicalEntitiesButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      fontWeight: '400',
      color: 'white',
      marginTop: 10,
      // borderTopLeftRadius: 45,
      // borderBottomLeftRadius: 45,
      // borderTopRightRadius: 45,
      // borderBottomRightRadius: 45,
    },
    OpportunityTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      fontWeight: '400',
      color: 'white',
      marginTop: 10,
      marginBottom: 10,
      // borderTopLeftRadius: 45,
      // borderBottomLeftRadius: 45,
      // borderTopRightRadius: 45,
      // borderBottomRightRadius: 45,
    },

    opportunityDescContainer: {
      display: 'flex',
      flexDirection: 'row',
      fontWeight: '400',
      color: 'white',
      // marginTop: 'auto',
      textAlignVertical: 'center',
      // borderTopLeftRadius: 45,
      // borderBottomLeftRadius: 45,
      // borderTopRightRadius: 45,
      // borderBottomRightRadius: 45,
    },

    medicalEntitiesImagesContainer: {
      height: 97,
      width: 125,
      marginLeft: 'auto',
    },
    researchImagesContainer: {
      height: 125,
      width: 125,
      //margin: 'auto',
      marginTop: 30,
      marginRight: 30,
      marginLeft: 30,
    },
    medicalentitiesimages: {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      borderTopRightRadius: 20,
      // borderBottomRightRadius: 35,
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      // objectFit: "fill",
      marginLeft: 'auto',
      color: '#ffffff',
    },
    researchImages: {
      borderRadius: 100,
      // borderBottomRightRadius: 35,
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      // objectFit: "fill",
      marginLeft: 'auto',
      color: '#ffffff',
    },
    background: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffff',
    },
    backgroundAlt: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffff',
    },
    profile: {
      backgroundColor: 'transparent',
      alignItems: 'flex-end',
      marginLeft: 15,
      fontWeight: '400',
    },
    logo: {
      marginBottom: 10,
      zIndex: 999999,
      alignItems: 'flex-start',
      width: 250,
      height: 100,
    },
    error: {
      fontSize: 14,
      color: '#FF0000',
      paddingLeft: 25,
      paddingTop: 4,
    },
    success: {
      textAlign: 'center',
      fontSize: 14,
      color: '#c9deea',
      paddingLeft: 25,
      paddingTop: 4,
    },
    image: {
      marginBottom: 10,
      zIndex: 999999,
      alignItems: 'center',
      justifyContent: 'center',
      width: 250,
      height: 100,
    },
    privacyPolicy: {
      margin: '1%',
      textAlign: 'center',
      color: '#FFFFFF',
    },
    // Notifications
    //Item
    notificationsItemCard: {
      borderRadius: 5,
      borderWidth: 1,
      marginBottom: 20,
    },
    notificationsItemTitle: {
      fontSize: 16,
    },
    notificationsItemRight: {
      marginRight: 20,
    },
    notificationsItemContent: {
      backgroundColor: '#0a638c',
    },
    notificationsItemContentDescription: {
      marginTop: 10,
      marginBottom: 10,
    },
    notificationsItemContentDate: {
      color: '#a0b9de',
    },
    // Notifications
    // Panel
    notificationsPanel: {
      zIndex: 100,
      position: 'absolute',
      maxHeight: '40%',
      maxWidth: '90%',
      borderRadius: 10,
      overflowY: 'scroll',
      overflowX: 'hidden',
      right: 0,
      top: 50,
      boxShadow:
        '0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)',
    },
    notificationsPanelCard: {
      borderRadius: 0,
      backgroundColor: '#C9DEEA',
      maxWidth: 620,
    },
    notificationsPanelTitle: {
      color: '#0F153A',
      fontWeight: 'bold',
    },
    notificationsPanelEmpty: {
      borderRadius: 5,
      borderWidth: 1,
      marginBottom: 20,
    },
    notificationsPanelEmptyTitle: {
      margin: 39,
    },
    notificationsPanelEmptyTitleText: {
      fontSize: 16,
    },
    // Icon
    notificationIconNavbar: {
      marginLeft: 'auto',
      marginTop: 5,
      marginRight: 5,
    },
    picker: {
      width: '100%',
      marginTop: 15,
      marginLeft: 20,
      marginRight: 20,
      borderColor: 'white',
      borderWidth: 1,
      borderRadius: 30,
      alignSelf: 'center',
      height: 64,
      color: 'white',
      backgroundColor: '#0f153a',
      margin: 8,
      paddingHorizontal: 10,
      fontSize: 16,
    },
    pickerText: {
      height: 60,
      width: '100%',
      color: '#ffffff',
      fontSize: 16,
      ...Platform.select({
        android: {
          height: 40,
          paddingHorizontal: 10,
        },
      }),
    },
    connectFooter: {
      textAlign: 'right',
      marginRight: 20,
      fontSize: 18,
      color: '#0f153a',
      fontWeight: '700',
    },
    entityTitle: {
      fontSize: 30,
      color: '#0f153a',
      fontWeight: '700',
      marginTop: 20,
      marginLeft: 40,
    },
    entityTitle2: {
      fontSize: 25,
      color: '#0f153a',
      fontWeight: '700',
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 40,
      marginRight: 40,
      whiteSpace: 'pre-line',
    },
    entitySubTitle: {
      flex: 1,
      marginLeft: 40,
      marginRight: 40,
      flexDirection: 'row',
    },
    logoContainer: {
      //position: 'absolute',
      // top: 40,
      // left: 40,
      height: 70,
      width: 235,
      //margin: 'auto',
      marginRight: 40,
      marginLeft: 'auto',
      //marginTop: 10,
    },
    logoImage: {
      //borderRadius: 100,
      //overflow: 'hidden',
      height: '100%',
      width: '100%',
      marginLeft: 'auto',
      color: '#ffffff',
    },
    android: {
      ...Platform.select({
        android: {
          width: 280,
          marginTop: 15,
          borderColor: 'white',
          borderWidth: 1,
          borderRadius: 30,
          justifyContent: 'center',
          height: 64,
          color: 'white',
          margin: 8,
          paddingHorizontal: 10,
        },
      }),
    },
  });

export default Styles;
