/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import uuid from 'react-native-uuid';
import {
  ActivityIndicator,
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableHighlight,
} from 'react-native';
import {useTheme, Button} from 'react-native-paper';
import {environment} from '../environments/environment.prod';
import Question from './Question';
import { AnsVal, CallBackVar } from '../models/Index';


const MedQuestions = ({route, navigation}) => {
  
  const {
    colors: {
      accent,
      surface,
    },
  } = useTheme();
  const {category, token, userProfile} = route.params;
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [answers, setAnswers] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [datacount, setDatacount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [dir, setDir] = useState(null);

  const navigateToPage = (page: string) => {
    navigation.navigate(page, {token, userProfile});
  };

  const updateUserAnswers = (callback_var: CallBackVar ) => {
    console.log("callback_var UpdateUserAnswers", callback_var);
    console.log('UserAnswers: ', userAnswers);
    console.log('length', Object.keys(callback_var).length);
    // let ansVal: any = { 0/1/2
    //   Answer: textValue,
    //   QuestionId: data.QnId
    // };
    // let title_value = { 4
    //   title: title, 
    //   value: value,
    //   QuestionId: QuestionID,
    //   OptionAnsVal: data.OptionAnsVal
    // }
    // Uses the number of objects sent to determaine if multi answer or not
    // TODO:: Change how it knows multi answer, maybe check multianswer variable
    //if(Object.keys(callback_var).length == 4) { // Type : 4
    if(callback_var.isMultiple) { // Type : 4
      console.log('Type 4');
      if (userAnswers) { //update true/false of QuestionText, if UserAsnwers is already set
        console.log('UserAnswers is EXISTS: ', userAnswers);
        let newArr = userAnswers;
        newArr.OptionalAnswersId = callback_var.OptionalAnswersId;
        let objIndex = newArr.MultiAnswerVal.findIndex((obj => obj.QuestionText == callback_var.title));
        if(objIndex == -1)
        {
          return;
        }else{
          newArr.MultiAnswerVal[objIndex].Answer = callback_var.value;
          newArr.MultiAnswerVal[objIndex].OptionalAnswersId = callback_var.OptionalAnswersId;
          setUserAnswers(newArr);
          console.log("userAnswers1", userAnswers)
        }
      } 
      else { //If UserAsnwers is null, create a object and set the state
        //TODO: Error in MultiAnswer value, only registers one at a time when new
        console.log('UserAnswers is NULL: ', userAnswers);
          let objIndex = answers.findIndex((obj => obj.QuestionId == callback_var.QuestionId));
          if (objIndex == -1) { //Not found in getUserAnswer API, so create it
            let MultiAnswerVal = [];
            let ansVal: AnsVal = { //UserAnswers object 
                UserId: userProfile.UserId,
                QuestionId: callback_var.QuestionId,
                Answer: "MultipleAnswers",
                OptionalAnswersId: callback_var.OptionalAnswersId,
                MultiAnswerVal: []
            };
            console.log('callback_var.OptionAnsVal:', callback_var.OptionAnsVal)
            //For multiple answers and UserAnswerBoolean
            //TODO: Check for multiple answer error, counts as multi when only one answer
            // for(let i = 0; i < callback_var.OptionAnsVal.length; i++){

            //   MultiAnswerVal.push({
            //         Id: uuid.v4(),
            //         UserId: userProfile.UserId,
            //         QuestionId: callback_var.OptionAnsVal[i].QuestionId,
            //         Answer: callback_var.title == callback_var.OptionAnsVal[i].OptionalValue ? true : false,
            //         QuestionText: callback_var.OptionAnsVal[i].OptionalValue,
            //         OptionalAnswersId: callback_var.OptionalAnswersId,
            //       })

            // }
              callback_var.OptionAnsVal.forEach((option) => { //UserAnswerBooleans object
                MultiAnswerVal.push({
                  Id: uuid.v4(),
                  UserId: userProfile.UserId,
                  QuestionId: option.QuestionId,
                  Answer: callback_var.OptionalAnswersId == option.OptionalAnswersId ? true : false,
                  QuestionText: option.OptionalValue,
                  OptionalAnswersId: option.OptionalAnswersId,
                })
              });
              console.log('callbak MultiAnswers: ', MultiAnswerVal);
            ansVal.MultiAnswerVal = MultiAnswerVal;
            setUserAnswers(ansVal);
          } 
          else { //answer found
            console.log('Answer Found 13: ', callback_var)
            let ansFound = answers.filter((i) => i.QuestionId == callback_var.QuestionId).reduce(k => k);
            let objIndex = ansFound.MultiAnswerVal.findIndex((obj => obj.QuestionText == callback_var.title));
            if(objIndex == -1){//if newly added optionVal is not present in user answers
                ansFound.MultiAnswerVal.push({
                  Answer: callback_var.value,
                  Id: uuid.v4(),
                  QuestionId: callback_var.QuestionId,
                  QuestionText: callback_var.title,
                  OptionalAnswersId: callback_var.OptionalAnswersId,
                  UserId: userProfile.UserId,
                }
                )
                console.log("AnsFound for multiple: ", ansFound)
                setUserAnswers(ansFound);
            } 
            else {
              ansFound.MultiAnswerVal[objIndex].Answer = callback_var.value;
              setUserAnswers(ansFound);
            }
        }
        console.log("USER Answers for multiple: ", userAnswers)
      }
    } 
    
    else { // Type: 0/1/2
      console.log('Type 0/1/2', callback_var);
      let objIndex = answers.findIndex((obj => obj.QuestionId == callback_var.QuestionId));
        if (objIndex == -1) { //Not found in getUserAnswer API, so create it
          let ansVal = {
            UserId: userProfile.UserId,
            QuestionId: callback_var.QuestionId,
            Answer: callback_var.Answer,
            OptionalAnswersId: callback_var.OptionalAnswersId,
            MultiAnswerVal: [],
          };
          setUserAnswers(ansVal);
          console.log("userAnswers4", userAnswers)
        } 
        else { //answer found
          console.log('Found!');
          // let objIndex = ansFound.MultiAnswerVal.findIndex((obj => obj.QuestionText == callback_var.title));
          // ansFound.MultiAnswerVal[objIndex].Answer = callback_var.value;
          let ansFound = answers.filter((i) => i.QuestionId == callback_var.QuestionId).reduce(k => k);
          ansFound.Answer = callback_var.Answer;
          ansFound.OptionalAnswersId = callback_var.OptionalAnswersId;
          setUserAnswers(ansFound);
        }
    }
  };

  //Post user answers
  const insertOrUpdateAns = async () => {
    console.log('next data we wanted-------->', userAnswers);
  if(userAnswers != null) {
    var objIndex = answers.findIndex(obj => obj.QuestionId == userAnswers.QuestionId);
    if (Object.keys(userAnswers).length !== 0) {
      if (objIndex == -1) {
        setAnswers((answers) => [...answers, userAnswers]);
      } else {
        let newArr = [...answers];
        newArr[objIndex].Answer = userAnswers.Answer;
        setAnswers(newArr);
      }
    }
  }

  console.log("Answer to be inserted: ", userAnswers);
    
    try { 
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/postUserAnswers',
        {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(userAnswers),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          //TODO handle success failure

          // if(data.Success)
          // {
          console.log('insertOrUpdateAns: ', data);
          //   // navigation.navigate("MedicalEntities", {token: data.Token, userID: data.UserId})
          // }
          // else
          // setGenericError(data.Message)
        })
        .catch((error) => {
          console.log(error);
        });
    } finally {
      setUserAnswers(null);
    }
  };

  const getMedQuestions = async () => {
    try {
      // console.log(userProfile.UserId);
      const response_qn = await fetch(
        environment.ApiURL +
          `/ParticipantPortal/getMedicalQuestions?categoryId=${category.Id}&userId=${userProfile.UserId}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      const json_qn = await response_qn.json();
      setData(json_qn);
      const response_ans = await fetch(
        environment.ApiURL +
          `/ParticipantPortal/getUserAnswers?userId=${userProfile.UserId}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      const json_ans = await response_ans.json();
      setAnswers(json_ans);
      console.log('user answer:', json_ans);
      console.log('Answer after setting: ', answers)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMedQuestions();
    handleClickPagination(dir, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickPagination = (direction: string, update: boolean) => {
    if(update) {
      insertOrUpdateAns();
    }
    console.log('handleClickPagination: ', answers);
    // handle out of bounds
    if (direction === 'right') {
      setDir(direction)
      // if count goes over length of data array, return out
      setQuestionCount(questionCount + 1);
      if (questionCount === data[datacount].Questions.length - 1) {
        if (datacount == data.length - 1) {
          alert("That was the last question of this section, returning to main page.")
          setDatacount(0);
          setQuestionCount(0);
          navigateToPage('Medical Entities');
        } else {
          setDatacount((datacount) => datacount + 1);
          // console.log(datacount);
          setQuestionCount(0);
          // console.log(questionCount);
        }
      }
    } else if (direction === 'left') {
      setDir(direction)
      if (datacount == 0 && questionCount == 0) {
        // console.log("You are at question one")
      }

      if (datacount != 0) {
        if (questionCount == 0) {
          // console.log('left click => data[datacount]:', data[datacount]);
          // console.log('left click => data[datacount - 1]:', data[datacount - 1].Questions.length - 1);
          setQuestionCount(data[datacount - 1].Questions.length - 1);
          setDatacount((datacount) => datacount - 1);
        }
      }
      if (questionCount <= 0) {
        return;
      } else {
        setQuestionCount(questionCount - 1);
      }
    }
  };
  return (
    <View style={styles.wrapper}>
    <View style={styles.container}>
      <View>
        <Button  
          style={styles.forgot}        
          mode="text"
          onPress={() => 
            navigateToPage('Medical Entities')
          
          }
          icon="chevron-left">
          <Text>Back</Text>
        </Button>
      </View>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flex: 1}}>
          <Text style={styles.header}>{category.EntityName.charAt(0).toUpperCase() + category?.EntityName.slice(1)}</Text>
          <Text style={styles.subHeader}>{data[datacount]?.Name.toUpperCase()}</Text>
          
          <View style={[styles.questionContainer, {backgroundColor: surface}]}>
            <Text style={styles.questionCountText}>Question {`${questionCount + 1} / ${data[datacount].Questions.length}`}</Text>
            <View style={styles.questionIndicator}>
              <View style={[styles.currentQuestionIndicator, {width: `${(((questionCount + 1) / data[datacount].Questions.length) * 100).toFixed(0)}%`}]}></View>
            </View>

            <Question
              data={data[datacount].Questions[questionCount]}
              userData={answers}
              callback={updateUserAnswers}
              UserId={userProfile.UserId}
              direction = {handleClickPagination}
              dirClicked = {dir}
              parentType = {data[datacount].Questions.filter(i=>i.QnId == data[datacount].Questions[questionCount].ParentId)}
              >
            </Question>
  
            <View style={styles.buttonsContainer}>
              <TouchableHighlight
                style={[styles.button, {paddingLeft: 5}]}
                underlayColor="transparent"
                onPress={() => handleClickPagination('left', true)}>
                <Text style={styles.buttonText}>{"< Previous"}</Text>
              </TouchableHighlight>
              <TouchableHighlight
                style={[styles.button, {backgroundColor: accent}]}
                underlayColor={accent}
                onPress={() => handleClickPagination('right', true)}>
                <Text style={styles.buttonText}>{"NEXT >"}</Text>
              </TouchableHighlight>
            </View>
          </View>
        </View>
      )}
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 1,
    marginTop: 15,
    fontWeight: '400',
    color: '#0a638c',
    ...Platform.select({
      ios: {
      marginTop: 20,
    },
    })
  },
  picker: {
    width: 'auto',
    marginTop: 15,
    marginHorizontal: 20,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 30,
    alignSelf: 'center',
    height: 64,
    color: 'white',
    backgroundColor: '#0f153a',
    margin: 8,
    paddingHorizontal: 10,
  },
  container: {
    flex: 1,
    padding: 14,
    minWidth: 300,
    margin: "auto",
    width: "100%",
  },
  header: {
    fontSize: 22,
    textAlign: "center",
    fontWeight: "700",
    color: '#0f153a',
  },
  subHeader: {
    fontSize: 11,
    paddingBottom: 16,
    textAlign: "center",
    fontWeight: "500",
    color: '#0a638c',
  },
  questionContainer: {
    flex: 1,
    padding: 28,
    borderRadius: 25,
  },
  questionCountText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  questionIndicator: {
    backgroundColor: "#c9deea",
    width: "100%",
    height: 20,
    borderRadius: 25,
    marginTop: 10,
  },
  currentQuestionIndicator: {
    backgroundColor: "#F1955B",
    height: 20,
    borderRadius: 25,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: "auto",
    paddingTop: 22,
  },
  button: {
    textAlign: "center",
    borderRadius: 25,
    paddingHorizontal: 35,
    paddingVertical: 7.5,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
  },
});

export default MedQuestions;
