/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import {Picker} from '@react-native-picker/picker';
// import {lookup} from 'zipcodes-nrviens';
import {environment} from './../environments/environment.prod';
import {
  TextInput,
  Card,
  Title,
  Button,
  HelperText,
  useTheme,
} from 'react-native-paper';

const UpdateProfile = ({navigation, route}) => {
  const {token, userProfile} = route.params;

  const navigateToPage = (page: string) => {
    navigation.navigate(page, {token: token, userProfile: newUserProfile});
  };  

   const {
    colors: {
      accent,
      surface,
    },
  } = useTheme();

  //checkboxes
  const [selectedOrganization, setSelectedOrganization] = React.useState(userProfile.EntityIdentity);
  const [gender, setGender] = useState(userProfile.Gender);
  //textboxes
  const [birthday, setBirthday] = useState(userProfile.Birthday.toString().slice(5, 7) + "-" + userProfile.Birthday.toString().slice(8, 10) + "-" + userProfile.Birthday.toString().slice(0, 4) );
  const [primaryPhone, setPrimaryPhone] = useState(userProfile.HomePhone);
  const [secondayPhone, setSecondaryPhone] = useState(userProfile.CellPhone);
  const [address, setAddress] = useState(userProfile.Address1);
  const [addressCont, setAddressCont] = useState(userProfile.Address2);
  const [fname, setName] = useState(userProfile.FirstName);
  const [lastName, setLastName] = useState(userProfile.LastName);
  const [zipcode, SetZipcode] = useState(userProfile.Zip);
  const [newUserProfile, setNewUserProfile] = useState(userProfile);

  //error checking
  const [genericError, setGenericError] = useState('');
  const [success, setSucess] = useState('');

  //mic
  const [state, setState] = useState(userProfile.State);
  const [city, setCity] = useState(userProfile.City);
  const [organizations, setorganizations] = useState([]);

  const updateLocalUser = async () => {

    try {
      const response = await fetch(
        environment.ApiURL +
          `/ParticipantPortal/getuserprofile?userId=${userProfile.UserId}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      ).then((response) => response.json()).then((data) => {
            setSucess('User Profile Updated');
            console.log('Data:', data);
            setNewUserProfile(data);
            }).catch((error) => {
            console.error(error);
            setGenericError(error);
        });
    } finally {
    }
  };



  const getOrganizations = async () => {
    try {
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/getOrganizations',
      );
      const json = await response.json();
      setorganizations(json);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(()=> {
    getOrganizations();
  },[])



  const updateUser = async () => {
    const json: string =
      '{"City": "' +
      city +
      '", "State": "' +
      state +
      '", "UserId": ' +
      userProfile.UserId +
      ', "Gender": ' +
      gender +
      ', "FirstName": "' +
      fname +
      '" , "LastName": "' +
      lastName +
      '", "Birthday": "' +
      birthday +
      '", "CellPhone": "' +
      secondayPhone +
      '", "HomePhone": "' +
      primaryPhone +
      '", "Address1": "' +
      address +
      '", "Address2": "' +
      addressCont +
      '", "Zip": "' +
      zipcode +
      '", "EntityIdentity": "' +
      selectedOrganization  +
      '"}';

      try {
        const response = await fetch(
          environment.ApiURL + '/ParticipantPortal/updateuserprofile',
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: json,
          },
        )
          .then((response) => response.json())
          .then((data) => {})
          .catch((error) => {
            console.error(error);
            setGenericError(error);
          });
      } finally {
        updateLocalUser();
      }
    };

  const makeRequest = async () => {
    if (!fname) {
      setGenericError('Enter a name');
      return;
    }

    if (!lastName) {
      setGenericError('Enter a last name');
      return;
    }

    if (!birthday) {
      setGenericError('Enter a birthday');
      return;
    }

    if (!primaryPhone) {
      setGenericError('Enter a primary phone');
      return;
    }

    if (!address) {
      setGenericError('Enter an address');
      return;
    }

    if (!zipcode) {
      setGenericError('Enter a zipcode');
      return;
    }

    if (!city) {
      setGenericError('Not a valid zipcode');
      return;
    }

    setGenericError('');
    updateUser();
  };

  async function getZipInfo(number: string) {
    if (number.length !== 5) {
      return;
    } else {
      try {
        const response = await fetch('https://ziptasticapi.com/' + number);
        const json = await response.json();
        if (json) {
          console.log(json);
          const cityName = json.city || '';
          setCity(cityName);
          const stateName = json.state || '';
          setState(stateName);
        }
      } catch (error) {
        console.error(error);
      }
    }
    SetZipcode(number);
  }

  return (
    <SafeAreaView>  
      <ScrollView style={{width: "100%"}}>
        <View style={styles.wrapper}>
          <Card style={styles.card}>
            <View style={styles.logo}>
            {Platform.OS === 'web' ? (
          <TouchableOpacity activeOpacity = { .5 } onPress={() => navigateToPage('Medical Entities')}>
          <Image
            style={styles.image}
            source={require('../assets/logo.png')}
          />
          </TouchableOpacity>
            ) : (
              <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
            )}
            </View>
            <Card.Content>
              <Title style={styles.title}>Profile</Title>
            </Card.Content>
            <View style={styles.container}>
            <View style={styles.referral}>
              <TouchableOpacity style={styles.android}>
                <Picker
                  label="Affiliated Organization"
                  style={styles.picker}
                  itemStyle={styles.pickerText}
                  selectedValue={selectedOrganization}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedOrganization(itemValue)
                  }>
                   
                    {organizations.map((temp) => {
                      
                      return <Picker.Item label={temp.Name} value={temp.EntityId} />;
                    })}     
                </Picker>
              </TouchableOpacity>
            </View>
              <View style={styles.referral}>
                <TouchableOpacity style={styles.android}>
                  <Picker
                    style={styles.picker}
                    label="Gender"
                    disabled={true}
                    selectedValue={userProfile.Gender}
                    onValueChange={(itemValue) =>
                      setGender(itemValue)
                    }>
                    <Picker.Item label="Select Gender" value="-1" />
                    <Picker.Item label="Male" value="0" />
                    <Picker.Item label="Female" value="1" />
                    <Picker.Item label="Non-Binary" value="2" />
                  </Picker>
                </TouchableOpacity>
              </View>           
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="First Name"
                editable={true}
                defaultValue={userProfile.FirstName}
                onChangeText={(text) => setName(text)}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Last Name"
                editable={true}
                defaultValue={userProfile.LastName}
                onChangeText={(text) => setLastName(text)}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Date of Birth (MM-DD-YYYY)"
                defaultValue={userProfile.Birthday.toString().slice(5, 7) + "-" + userProfile.Birthday.toString().slice(8, 10) + "-" + userProfile.Birthday.toString().slice(0, 4) }
                onChangeText={(text) => setBirthday(text)}
                disabled={true}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Primary Phone"
                defaultValue={userProfile.HomePhone}
                onChangeText={(text) => setPrimaryPhone(text)}
                keyboardType="phone-pad"                  
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Secondary Phone"
                defaultValue={userProfile.CellPhone}
                onChangeText={(text) => setSecondaryPhone(text)}
                keyboardType="phone-pad"
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Address"
                defaultValue={userProfile.Address1}
                onChangeText={(text) => setAddress(text)}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Address Continued"
                defaultValue={userProfile.Address2}
                onChangeText={(text) => setAddressCont(text)}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                label="Zip Code"
                defaultValue={userProfile.Zip.toString()}
                maxLength={5}
                keyboardType='numeric'
                onChangeText={(number) => getZipInfo(number)}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                value={city}
                disabled={true} 
                placeholder={'City'}
              />
              <TextInput
                textAlign="left"
                mode="outlined"
                theme={{colors: {text: 'white', primary: accent, background: surface}}}
                style={styles.inputContainerStyle}
                value={state} 
                disabled={true} 
                placeholder={'State'}
              />
              <Button
                style={[styles.button, {backgroundColor: accent}]}
                mode="contained"
                onPress={() => makeRequest()}>
                <Text>Update</Text>
              </Button>
              <Button
                style={styles.forgot}
                mode="contained"
                onPress={() => navigateToPage('Manage Account')}
                icon="chevron-left">
                <Text>Back</Text>
              </Button>
              {genericError.length != 0 && (
                <HelperText type="error" style={styles.error} visible={true} >
                  {genericError}
                </HelperText> 
              )} 
              {success.length != 0 && (
                <HelperText type="error" style={styles.success} visible={true} >
                  {success}
                </HelperText> 
              )}               
            </View>
          </Card>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 15,
    fontWeight: '400',
  },
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
  },
  title: {
    textAlign: 'center',
    marginTop: "5%",
  },
  card: {
    justifyContent: 'center',
    borderRadius: 30,
    width: "100%",
    minWidth: 350,
    maxWidth: 1000,
    height: 'auto',
    margin: 'auto',
    marginBottom: 30,
    padding: "5%",
    ...Platform.select({
      web: {
      marginTop: 50,
    },
    })
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
    marginBottom: 50,
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'row',
    padding: "5%",
  },
  referral: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
  },
  picker: {
    width: "100%",
    marginTop: 15,
    marginLeft: 20,
    marginRight: 20,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 30,
    alignSelf: 'center',
    height: 64,
    color: 'white',
    backgroundColor: '#0f153a',
    margin: 8,
    paddingHorizontal: 10,
    fontSize: 16,
  },
  instructions: {
    display: 'flex',
    textAlign: 'center',
    margin: 'auto',
    fontSize: 18,
  },
  description: {
    marginBottom: 15,
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  buttonAlt: {
    margin: 8,
    padding: 10,
    marginTop: 10,
    backgroundColor: '#f1955b',
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: "15%",
  },
  containerStyle: {
    flex: 1,
  },
  scrollViewStyle: {
    flex: 1,
    padding: 15,
    justifyContent: 'center',
  },
  headingStyle: {
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 40,
  },
  pickerLabel: {
    color: 'white',
  },
  input: {
    backgroundColor: 'transparent',
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  success: {
    fontSize: 14,
    color: '#c9deea',
    paddingLeft: 25,
    paddingTop: 4,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 26,
  },
  pickerText: {
    height: 60,
    width: "100%",
    color: '#ffffff',
    fontSize: 16,
    ...Platform.select({
      android: {
        height: 40,
        paddingHorizontal: 10,
      },
    })
  },
  android: {
    ...Platform.select({
      android: {
        width: 280,
        marginTop: 15,
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 30,
        justifyContent: 'center',
        height: 64,
        color: 'white',
        margin: 8,
        paddingHorizontal: 10,
      },
    })
  },
});

export default UpdateProfile;
