/* eslint-disable eqeqeq */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState, useCallback } from 'react';
import {
  Text,
  View,
  ScrollView,
  Platform,
  StyleSheet,
  Picker,
  TouchableOpacity,
} from 'react-native';
import {
  useTheme,
  TextInput,
  HelperText
} from 'react-native-paper';
import CheckBoxx from './CheckBox';
import WebPicker from './WebPicker';
import RadioButtonss from './radioButton';
import { DatePickerModal } from 'react-native-paper-dates';
import {IconButton} from 'react-native-paper';
import { AnswerValue, AnsVal, QuestionObj, UserAnswerData, OptionValues } from '../models/Index';


const Question = ({ children, data, userData, callback, UserId, direction, dirClicked, parentType }) => {
  let parentQnType = parentType.length != 0 ? parentType[0].Type : -1;

  const {
    colors: {
      accent,
      surface,
    },
  } = useTheme();

  useEffect(() => {
    getPreviousDateAnswer();
    getRadioAnswer();
    getTextAnswer();
    getSelectedValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getPreviousSelectedValue = () => {
    //this is for the picker to display what has been answered previously
    let answer: string = '';
    userData.forEach((question) => {
      if (question?.QuestionId === data?.QnId) {
        // console.log('found question...', question);
        answer = question?.Answer;
        console.log('getPreviousSelectedValue, Question: ', question);
        console.log('Answer: ', question.Answer);
      }
    });
    return answer;
  };

  const getSelectedValue = () => {
    //this is for the picker to display what has been answered previously
    //TODO: Change compare to use ID instead answer value
    let answer: string = '';
    console.log('getSelectedValue DATA: ', data.OptionAnsVal[0]);
    userData.forEach((question) => {
      if (question.QuestionId === data.QnId) {
        console.log('found question...', question);
        let obj = data.OptionAnsVal.find(e => e.OptionalAnswersId == question.OptionalAnswersId)
        answer = obj?.OptionalValue//question.Answer;
      }
    });
    setSelectedValue(answer);
    return answer;
  };

  const getPreviousTextAnswer = () => {
    //this is for the text input to display what has been answered previously
    let textanswer: string = '';
    userData.forEach((question) => {
      if (question.QuestionId === data.QnId) {
        console.log('Previous TEXT answer FOUND...', question);
        let obj = data.OptionAnsVal.find(e => e.OptionalAnswersId == question.OptionalAnswersId)
        textanswer = obj?.OptionalAnswersId;
      }
    });
    return textanswer;
  };

  const getPreviousDateAnswer = () => {
    //this is for the text input to display what has been answered previously
    console.log('Previous: Date: ', data);
    let textanswer: string = '';
    let date: Date = new Date();
    userData.forEach((question) => {
      if (question.QuestionId === data.QnId) {
        console.log('Previous DATE answer FOUND...', question);
        //let obj = data.OptionAnsVal.find(e => e.OptionalAnswersId == question.OptionalAnswersId)
        //textanswer = obj?.OptionalAnswersId;
        //!isNaN(new Date(textanswer).getDate());
        try {
          if(!isNaN(new Date(question.Answer).getDate())) {
            date = new Date(question.Answer);
            setDatePickedText(question.Answer);
            setDatePicked(date);
          } else {
            date = new Date();
            setDatePicked(date);
            setDatePickedText(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDay); 
          }
        } catch (error) {
          return;
        }
      }
    });
  };

  // unsure if it needs to compare user answer and oprional answer based on id
  const getTextAnswer = () => {
    //this is for the text input to display what has been answered previously
    let textanswer: string = '';
    userData.forEach((question) => {
      if (question.QuestionId === data.QnId) {
        console.log('found question...', question);
        textanswer = question.Answer;
      }
    });
    setTextValue(textanswer);
    if(data.Type == 6)
    {
      numericChange(textanswer);
    }
    return textanswer;
  };

  //function for text
  const textHandler = (text: string) => {
    setTextValue(text);
    valueOfText(text);
  };

  //TODO: Fix numeric check with regx
  const numericChange = (text: string) => {

    //removes characters from the string.
    if (/^\d+$/.test(text)) { }
    else {
      setTextValue(text.slice(0, -1))
    }

    //this is done twice for the edge cases where the splice function fails at removing the char fron the end.
    if(/^\d+$/.test(text) && text.length > 0) { }
    else{
      setMinMaxError("Please only enter numerical values.");
      return;
    }
    let currentValue = parseInt(text);
    setNumerical(currentValue);
    if(currentValue > data.Max)
    {
      setMinMaxError("Please enter a value smaller than " + data.Max)
    }
    else if(currentValue < data.Min)
    {
      setMinMaxError("Please enter a value larger than " + data.Min)
    }
    else
    {
      console.log("Got to setting");
      setMinMaxError("");
      textHandler(text);
    }
    console.log(minMaxError);
    setTextValue(text);
  };

  const valueOfText = (textValue: string) => {
    //ansVal.Answer = textValue;
    //data.OptionAnsVal[0].OptionalAnswersId
    let answer;
    let position = 0;
    for (let i = 0; i < data.OptionAnsVal.length; i++){
      if(data.OptionAnsVal[i].OptionalValue == textValue){
        answer = data.OptionAnsVal[i];
        position = i;
      }
    }
    console.log('FUNCTION CALL valueOfText: ');
    console.log('data.OptionAnsVal: ', data.OptionAnsVal[position]);
    console.log('Answer: ', answer);
    console.log('textValue: ', textValue);
    console.log('data: ', data);
    let ansVal: AnswerValue = {
      Answer: textValue,
      QuestionId: data.QnId,
      OptionalAnswersId: data?.OptionAnsVal[position]?.OptionalAnswersId, //answer.OptionalAnswersId,
      UserId: UserId,
      //TODO: Add Answer ID and index
    };
    callback(ansVal);
  };

  const pickerActivity = (itemselected: string) => {
    // alert("PICKER PRESSED");
    setSelectedValue(itemselected);
    drop(itemselected);
  };


  const drop = (selectedValue: string) => {
    //function for dropdown or picker to set the answer
    //ansVal.Answer = selectedValue;
    let answer;
    let position = 0;
    for (let i = 0; i < data.OptionAnsVal.length; i++){
      console.log('For loop v1: ', data.OptionAnsVal[i].OptionalValue ,' v2: ', selectedValue);
      if(data.OptionAnsVal[i].OptionalValue == selectedValue){
        answer = data.OptionAnsVal[i];
        position = i;
      }
    }
    console.log('FUNCTION CALL drop: ');
    console.log('data.OptionAnsVal: ', data.OptionAnsVal);
    console.log('Answer 1: ', answer);
    console.log('textValue: ', selectedValue);
    console.log('position: ', position);

    let ansVal: AnswerValue = {
      Answer: selectedValue,
      QuestionId: data.QnId,
      OptionalAnswersId: data.OptionAnsVal[position].OptionalAnswersId,
      UserId: UserId,
    };
    console.log('ansVal.Answer for drop', ansVal.Answer);
    callback(ansVal);
  };

  const [selectedValue, setSelectedValue] = useState(getPreviousSelectedValue()); //state for picker
  const [textValue, setTextValue] = useState(getPreviousTextAnswer()); //state for text

  //date picker hoocks
  const [openDate, setOpenDate] = useState(false);
  const [datePickedText, setDatePickedText] = useState("");
  const [datePicked, setDatePicked] = useState(new Date());

  //minmax stuff
  const [minMaxError, setMinMaxError] = useState("");
  const [numerical, setNumerical] = useState(-1);

  function onDismissSingle() {
    setOpenDate(false)
  }

  let ansVal: AnsVal;
  
  if (data.Type != 4) {
    // type is 0/1/2
    let objIndex = userData.findIndex((obj) => obj.QuestionId == data.QnId);
    if (objIndex == -1) {
      //TODO: check if OptionalAnswersId is needed
      let ansVal: AnsVal = {
        UserId: UserId,
        QuestionId: data.QnId,
        Answer: '',
        MultiAnswerVal: [],
        OptionalAnswersId: '',
      };
    } else {
      ansVal = userData
        .filter((i) => i.QuestionId == data.QnId)
        .reduce((k) => k);
    }
  }

  const onConfirmSingle = useCallback(
    (params) => {
      console.log(params);
      setOpenDate(false);
    },
    [setOpenDate, setDatePickedText]
  );

  const confirmSingle = (date: any) => {
    const words = date.date.toString().split(' ');
    const newDate = words[1] + " " + words[2] + " " + words[3] + " " + words[4]
    const date2 = new Date(newDate);
    dateHandeler(date2);
  }

  const dateHandeler = (date: Date) => {
    if(Platform.OS === 'web')
    {
      setDatePickedText(date.toString());
      //setDatePickedText(date.getUTCFullYear + "/" + date.getUTCMonth + "/" + date.getUTCDay)
      setDatePicked(date)
      valueOfDate(date);
    }
    else
    {
      const temp = date.toLocaleDateString('en-US')
      let words = temp.split("/")
      let dateOfText = words[2] + "-" + words[0] + "-" + words[1];
      setDatePickedText(date.toString());
      setDatePickedText(dateOfText)
      setDatePicked(date)
      valueOfDate(dateOfText);
    }
  }

  const valueOfDate = (textValue: any) => {
    // console.log(data);
    let answer;
    let position = 0;
    for (let i = 0; i < data.OptionAnsVal.length; i++){
      if(data.OptionAnsVal[i].OptionalValue == textValue){
        answer = data.OptionAnsVal[i];
        position = i;
      }
    }
    console.log('FUNCTION CALL valueOfText: ');
    console.log('data.OptionAnsVal: ', data.OptionAnsVal[position]);
    console.log('Answer: ', answer);
    console.log('textValue: ', textValue);
    let ansVal: AnswerValue = {
      Answer: textValue,
      QuestionId: data.QnId,
      OptionalAnswersId: data?.OptionAnsVal[position]?.OptionalAnswersId,
      UserId: UserId,
    };
    callback(ansVal);
  }

  // const valueOfDateMobile = (textValue, qestion) => {
  //   let ansVal: any = {
  //     Answer: textValue,
  //     QuestionId: qestion,
  //   };
  //   callback(ansVal);
  // }

  // TODO: Find where this is used
  const checkvalue = (title: string, value: boolean, QuestionID: string, OptionalAnswersId: string, test) => {
    //TODO: Add Answer ID 
    console.log('checkbox: checkValue: value: ', value);
    console.log('checkbox: checkValue: ansID: ', OptionalAnswersId);
    let answer;
    let position = 0;
    for (let i = 0; i < data.OptionAnsVal.length; i++){
      if(data.OptionAnsVal[i].OptionalValue == title){
        answer = data.OptionAnsVal[i];
        position = i;
      }
    }
    let title_value = {
      title: title,
      value: value,
      QuestionId: QuestionID,
      OptionAnsVal: data.OptionAnsVal,
      OptionalAnswersId: data.OptionAnsVal[position].OptionalAnswersId,
      isMultiple: true,
    };
    console.log('here', title_value);
    console.log('here data', data);
    console.log('here OptionalAnswersId', data.OptionAnsVal[position].OptionalAnswersId);
    console.log('here test', test);
    callback(title_value);
  };

  //radio new code
  const getPreviousRadioAnswer = () => {
    // let radioanswer: any = {
    //   OptionalValue: null,
    // };
    let radioanswer: string;
    userData.forEach((question) => {
      if (question.QuestionId === data.QnId) {
        console.log('getPreviousRadioAnswer - found question', question);
        radioanswer = question.Answer;
      }
    });
    // setRadioButtonSelection(radioanswer)
    // console.log('radioanswer..', radioanswer);
    return radioanswer;
  };
  const [radioButtonSelection, setRadioButtonSelection] = useState(
    getPreviousRadioAnswer(),
  );

  const getRadioAnswer = () => {
    //this is for the radio buttons to display what has been answered previously
    let radioanswer: string;
    userData.forEach((question) => {
      if (question.QuestionId === data.QnId) {
        console.log('found question...', question);
        // finding answer form answer id
        let obj = data.OptionAnsVal.find(e => e.OptionalAnswersId == question.OptionalAnswersId)
        radioanswer = obj?.OptionalValue//question.Answer;
      }
    });
    setRadioButtonSelection(radioanswer);
    // console.log('found radioanswer...', radioanswer);
    return radioanswer;
  };
  //radio code
  const onSelect = (item: OptionValues) => {
    // ansVal.Answer = item.OptionalValue;
    let answer;
    let position = 0;
    console.log('Radio, onSelect, loop: ', item);
    for (let i = 0; i < data.OptionAnsVal.length; i++){
      if(data.OptionAnsVal[i].OptionalValue == item.OptionalValue){
        console.log('Radio, onSelect, loop: true');
        answer = data.OptionAnsVal[i];
        position = i;
      }
    }
    console.log('FUNCTION CALL valueOfText: ');
    console.log('data.OptionAnsVal: ', data.OptionAnsVal[position]);
    console.log('Answer: ', answer);
    console.log('textValue: ', item);
    console.log('Radio, onSelect, data: ', data);
    let ansVal: AnswerValue = {
      Answer: item.OptionalValue,
      QuestionId: data.QnId,
      OptionalAnswersId: data.OptionAnsVal[position].OptionalAnswersId,
      UserId: UserId,
    };
    if (
      radioButtonSelection &&
      radioButtonSelection === item.OptionalValue
    ) {
      setRadioButtonSelection(null);
    } else {
      setRadioButtonSelection( item.OptionalValue);
    }
    console.log('sending to callback....', ansVal);
    callback(ansVal);
  };

  // TODO: find if it needs answerId
  const checkvaluefun = (data: OptionValues) => {
                  console.log('checkValFunction: ', data);
                  console.log('checkValFunction: USERDATA', userData);

                  var result = userData.filter((i) => i.QuestionId == data.QuestionId).length != 0 ? (userData
                    .filter((i) => i.QuestionId == data.QuestionId)
                    .map((j) => j.MultiAnswerVal)
                    .reduce((k) => k)
                    .filter((l) => l.OptionalAnswersId == data.OptionalAnswersId).length !=0 ? 
                    userData
                    .filter((i) => i.QuestionId == data.QuestionId)
                    .map((j) => j.MultiAnswerVal)
                    .reduce((k) => k)
                    .filter((l) => l.OptionalAnswersId == data.OptionalAnswersId)
                    .map((m) => m.Answer)
                    .reduce((n) => n)  : false)
                  : false

                  console.log('checkvaluefun: Value to be returned: ', result);

                  return result;

                  // return userData.filter((i) => i.QuestionId == data.QuestionId).length != 0 ? (userData
                  //     .filter((i) => i.QuestionId == data.QuestionId)
                  //     .map((j) => j.MultiAnswerVal)
                  //     .reduce((k) => k)
                  //     .filter((l) => l.QuestionText == data.OptionalValue).length !=0 ? 
                  //     userData
                  //     .filter((i) => i.QuestionId == data.QuestionId)
                  //     .map((j) => j.MultiAnswerVal)
                  //     .reduce((k) => k)
                  //     .filter((l) => l.QuestionText == data.OptionalValue)
                  //     .map((m) => m.Answer)
                  //     .reduce((n) => n)
                  //     .filter((i) => i.OptionalAnswersId == data.OptionalAnswersId)
                  //     .map((j) => j.OptionalAnswersId)
                  //     .reduce((n) => n)  : false)
                  //   : false
  }

  const mySwitch = (type: number) => {
    switch (type) {
      //Text Input
      case 0:
        return (
          <View style={styles.questionContainer}>
            {/* <Text>{"Answer:"}{data.answer}</Text> */}
            {/* <Text>{data.OptionalValue}</Text> */}
            {/* {console.log(data.OptionalValue)} */}
            {/* {data.answer = userData.filter((item)=>item.QuestionId == data.QnId).map(ansObj => ansObj.Answer)} */}
            <Text style={styles.questionText}>{data ? data.Value : ''}</Text>
            <TextInput
              // right={<TextInput.Affix text="lbs" />}
              textAlign="left"
              mode="outlined"
              theme={{ colors: { text: 'white', primary: accent, background: surface } }}
              style={styles.inputContainerStyle}
              // placeholder=""
              //placeholder={getTextAnswer()}
              onChangeText={(text) => textHandler(text)}
              value={textValue}
            />
          </View>
        );
      //Radio button
      case 1:
        return (
          <View style={styles.questionContainer}>
            <Text style={styles.typeCTA}>Select an answer.</Text>
            <Text style={styles.questionText}>{data ? data.Value : ''}</Text>
            {data.OptionAnsVal.map((item, i) => {
              return (
                <View style={styles.radioButtonContainer}>
                  <RadioButtonss
                    selectedOption={radioButtonSelection}
                    onSelect={onSelect}
                    data={item}
                  />
                </View>
              );
            })}
          </View>
        );
      //Picker or dropdown
      case 2:
        return (
          <ScrollView style={styles.scroll}>
            <View style={styles.questionContainer}>
              <Text style={styles.typeCTA}>Select an answer.</Text>
              <Text style={styles.questionText}>{data ? data.Value : ''}</Text>
              <TouchableOpacity style={styles.android}>
                <Picker
                  style={styles.picker}
                  itemStyle={styles.pickerText}
                  selectedValue={selectedValue}
                  onValueChange={(itemValue) => pickerActivity(itemValue)}>
                  {/* {
                  (data.answer = userData
                    .filter((item) => item.QuestionId == data.QnId)
                    .map((ansObj) => ansObj.Answer))
                } */}

                  <Picker.Item label={'Please select answer'} value="-1" />
                  {data.OptionAnsVal.map((item, key) => {
                    return <Picker.Item
                      key={key}
                      style={{ color: "white" }}
                      label={item.OptionalValue}
                      value={item.OptionalValue}
                    />;
                  })}
                </Picker>
              </TouchableOpacity>
            </View>
          </ScrollView>
        );
      //Checkbox
      case 4:
        return (
          <View style={styles.questionContainer}>
            <Text style={styles.typeCTA}>Select all that apply.</Text>
            <Text style={styles.questionText}>{data ? data.Value : ''}</Text>
           
            {data.OptionAnsVal.map((d, i) => (
              <CheckBoxx
                key={i}
                title={d.OptionalValue}
                value={ checkvaluefun(d)}
                QuestionID={d.QuestionId}
                OptionalAnswersId={d.OptionalAnswersId}
                test={d}
                checkvalue={checkvalue}
              />
            ))}
          </View>
        );
      //date
      case 5:
        return (
          <View style={styles.questionContainer}>
            <Text style={styles.questionText}>{data ? data.Value : ''}</Text>
            {Platform.OS === 'web' ? (
              <View style={styles.webPickerContainer}>
                <Text style={styles.webPickerText}>
                  <WebPicker style={styles.webPicker} value={datePickedText} onChange={(dateValue) => dateHandeler(dateValue)} />
                </Text>
              </View>
            ) : (
              <View style={styles.mobPickerContainer}>
                <Text style={styles.mobPickerText}>
                  {datePicked.toLocaleDateString('en-US', { timeZone: 'UTC'})}
                </Text>
                <IconButton
                  label={"Date of Birth"}
                  theme={{ colors: { text: 'white', primary: accent, background: surface } }}
                  color={'white'}
                  style={styles.mobDatePicker}
                  icon="calendar"
                  onPress={() => setOpenDate(true)} uppercase={false} mode="outlined"
                />
                <DatePickerModal
                  mode="single"
                  visible={openDate}
                  onDismiss={onDismissSingle}
                  date={datePicked}
                  onConfirm={onConfirmSingle}
                  onChange={(dateValue) => confirmSingle(dateValue)}
                />
              </View>
            )}
          </View>
        );
      //ranged numeric
      case 6:
        return (
          <View style={styles.questionContainer}>
            <Text style={styles.questionText}>{data ? data.Value : ''}</Text>
            <TextInput
              textAlign="left"
              keyboardType="numeric"
              mode="outlined"
              theme={{ colors: { text: 'white', primary: accent, background: surface } }}
              style={styles.inputContainerStyle}
              onChangeText={(text) => numericChange(text)}
              value={textValue}
              error={minMaxError.length !== 0}
            />
            {minMaxError.length !== 0 && (
              <HelperText type="error" style={styles.error} visible={true}>
                    {minMaxError}
              </HelperText>
              )} 
          </View>
        );
    }
  };

  var childcount: string = ''

  const parentChildren = (data: QuestionObj, userdata: UserAnswerData[]) => {

    // console.log("inside child parent")
    // console.log("data inside the parentchild.................",data)
    const parentindb = userdata.filter(id => data.ParentId == id.QuestionId).filter(ans => ans.Answer == data.DependentParentAnswerValue)

    if (data.ParentId != "00000000-0000-0000-0000-000000000000" && parentQnType != 4) {
      if (dirClicked == "right") {
        if (parentindb.length == 0) {
          //skip the current and move to next question
          // questioncount +=1
          childcount = "right"
          direction(childcount, false)
        }
      }
      else {
        const searchCheckAns = userdata.filter(id => data.ParentId == id.QuestionId).filter(ans => ans.Answer == data.DependentParentAnswerValue)
        if (searchCheckAns.length == 0) {
          childcount = "left"
          direction(childcount, false)
        }
      }
    }
  };

  return (
    <ScrollView style={styles.scroll}>
      <View style={styles.wrapper}>
        {data.AffirmativePercent != -1 && (
          <Text style={styles.italic}>
            {/* {data.AffirmativePercent}% OF BIOLINKED USERS{' '}
            {data.QnName.toUpperCase()} */}
          </Text>
        )}
        {parentChildren(data, userData)}
        {mySwitch(data.Type)}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  mobPickerText: {
    display: 'flex',
    justifyContent: 'center',
    color: '#ffffff',
    fontSize: 16,
    paddingTop: 20,
  },
  webPicker: {
    width: "100%",
    minWidth: 290,
    maxWidth: 1000,
    marginHorizontal: 20,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 30,
    alignSelf: 'center',
    height: 64,
    color: 'white',
    backgroundColor: '#0f153a',
    margin: 8,
    paddingHorizontal: 10,
    marginLeft: -10,
  },
  webPickerText: {
    color: '#ffffff',
    fontSize: 14,
    paddingTop: 4,
    fontFamily: 'sans-serif',
  },
  webPickerContainer: {
    margin: 8,
    marginHorizontal: 20,
    color: 'white',
  },
  mobPickerContainer: {
    borderWidth: 1,
    borderColor: "white",
    borderRadius: 50,
    height: 64,
    margin: 8,
    color: 'white',
    paddingLeft: 15,
  },
  mobDatePicker: {
    borderWidth: 1,
    borderColor: "transparent",
    borderRadius: 50,
    height: 64,
    margin: 8,
    color: 'white',
    ...Platform.select({
      ios: {
        marginLeft: '85%',
        marginTop: '-12%',
      },
      android: {
        marginLeft: '85%',
        marginTop: '-12%'
      },
    })
  },
  scroll: {
    width: "100%",
    margin: 'auto',
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
    color: 'white',
  },
  inputContainerStyle: {
    margin: 8,
  },
  picker: {
    width: 280,
    marginTop: 15,
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 30,
    justifyContent: 'center',
    height: 40,
    color: 'white',
    backgroundColor: '#0f153a',
    margin: 8,
    paddingHorizontal: 10,
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  pickerText: {
    height: 60,
    width: "100%",
    color: '#ffffff',
    fontSize: 16,
  },
  radio: {
    // flexDirection: 'row',
  },
  container: {
    // flex: 1,
    // paddingTop: 20,
    // alignItems: 'flex-end',
  },
  checkboxContainer: {
    //flexDirection: "row",
    // marginBottom: 20,
  },
  checkbox: {
    // alignSelf: 'center',
  },
  label: {
    // margin: 5,
  },
  radioButtonContainer: {
    minWidth: 280,
    maxWidth: 300,
    width: "100%",
  },
  radioButtonIcon: {
    // height: 14,
    // width: 14,
    // borderRadius: 7,
    // backgroundColor: '#98CFB6',
  },
  radioButtonText: {
    // fontSize: 25,
    // marginLeft: 16,
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    borderColor: 'white',
    color: 'white',
  },
  italic: {
    // fontStyle: 'italic',
  },
  // dropdown
  containerDropdown: {
    // backgroundColor: "1px solid red",
    // backgroundColor: "red",
  },
  typeCTA: {
    fontSize: 14,
    fontWeight: '200',
    color: 'white',
    paddingVertical: 20,
  },
  questionText: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    paddingBottom: 20,
  },
  // checkbox
  containerCheckbox: {},
  questionContainer: {
    paddingVertical: 20,
    margin: 'auto',
  },
  android: {
    ...Platform.select({
      android: {
        width: 280,
        marginTop: 15,
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 30,
        justifyContent: 'center',
        height: 64,
        color: 'white',
        margin: 8,
        paddingHorizontal: 10,
      },
    })
  },
});

export default Question;
