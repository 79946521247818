/* eslint-disable @typescript-eslint/camelcase */
import {unstable_createElement} from 'react-native-web';

const handler = (event, callback) => {
  callback(event.target.value);
};

export const WebPicker = ({value, style, onChange}) => {
  return unstable_createElement('input', {
    type: 'date',
    value: value,
    style: style,
    onInput: (event) => handler(event, onChange),
  });
};

export default WebPicker;
