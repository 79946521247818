/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
  View,
  Image,
  StyleSheet,
  Platform,
  ScrollView,
  SafeAreaView,
  Text,
  TouchableOpacity
} from 'react-native';
import {
  Card,
  Title,
  Button,
  useTheme
} from 'react-native-paper';

const ManageAccount = ({navigation, route}) => { 

  const {
    colors: {
      primary,
    },
  } = useTheme();

  const {token, userProfile} = route.params;

  const navigateToPage = (page: string) => {
    console.log(userProfile.UserId);
    navigation.navigate(page, {token, userProfile});
  };

  return (
    <SafeAreaView>
      <ScrollView>
        <Card style={styles.card}>
          <View style={styles.logo}>
          {Platform.OS === 'web' ? (
          <TouchableOpacity activeOpacity = { .5 } onPress={() => navigateToPage('Medical Entities')}>
          <Image
            style={styles.image}
            source={require('../assets/logo.png')}
          />
          </TouchableOpacity>
            ) : (
              <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
            )}
          </View>
          <Card.Content>
            <Title style={styles.title}>Manage Account</Title>
          </Card.Content>
          <View style={styles.container}>
            <Button
              style={styles.button}
              mode="contained"
              onPress={() => navigateToPage('Change Password')}>
              <Text>Change Password</Text>
            </Button>
            <Button
              style={styles.button}
              mode="contained"
              onPress={() => navigateToPage('Update Profile')}>
              <Text>Edit Profile</Text>
            </Button>
            <Button
              style={styles.forgot}
              mode="contained"
              onPress={() => navigateToPage('Medical Entities')}
              icon="chevron-left">
              <Text>Back</Text>
            </Button>
          </View>
        </Card>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
  },
  title: {
    textAlign: 'center',
    marginTop: "5%",
  },
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    height:'auto',
    width: "100%",
    minWidth: 350,
    maxWidth: 500,
    marginTop: 50,
    marginBottom: 30,
    padding: '5%',
    margin: 'auto',
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 15,
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: "15%",
  },
});

export default ManageAccount;
