/* eslint-disable @typescript-eslint/no-namespace */
import React, {useState, useEffect} from 'react';
import {StatusBar, Linking} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

import {DefaultTheme, Provider as PaperProvider} from 'react-native-paper';

// import {LoginScreen} from './LoginScreen';
// import {HomeScreen} from './HomeScreen';

import MedicalEntities from './components/medicalEntities';
import MedQuestions from './components/medQuestions';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ResetPassword from './components/ResetPassword';
import ConnectDonnorID from './components/ConnectDonnorID';
import TroubleSigningIn from './components/TroubleSigningIn';
import RetrieveUsername from './components/RetrieveUsername';
import RequestToken from './components/RequestToken';
import ChangePassword from './components/ChangePassword';
import ManageAccount from './components/ManageAccount';
import UpdateProfile from './components/UpdateProfile';
// import {environment} from './environments/environment.prod';

const Stack = createStackNavigator();

declare global {
  namespace ReactNativePaper {
    interface Theme {
      // myOwnProperty: boolean;
    }
    interface ThemeColors {
      // error: string;
      underlineColor: string;
    }
  }
}

const theme = {
  ...DefaultTheme,
  dark: false,
  roundness: 50,
  colors: {
    ...DefaultTheme.colors,
    primary: '#0a638c',
    accent: '#f1955b',
    background: 'transparent',
    surface: '#0f153a', //card bg
    error: '#B00020',
    text: 'white',
    disabled: '#f4f4f4',
    placeholder: 'white',
    backdrop: '#0f153a', //modal bg
    underlineColor: 'transparent',
  },
};

// Deep linking
//const useMount = (func): any => useEffect(() => func(), []);

/**
 * Gets the initial link that opened the application.
 * @returns url, processing
 */
// const useInitialURL = (): any => {
//   const [url, setUrl] = useState(null);
//   const [processing, setProcessing] = useState(true);
//   const getUrlAsync = async (): Promise<any> => {
//     // Get the deep link used to open the app
//     const initialUrl = await Linking.getInitialURL();
//     console.log();

//     setUrl(initialUrl);
//     setProcessing(false);
//   };

//   //getUrlAsync();
//   useEffect(() => {
//     getUrlAsync();
//   }, [url]);

//   return {url, processing};
// };

const Nav = () => {
  // // Deep linking
  //const useMount = (func): any => useEffect(() => func(), []);

  // /**
  //  * Gets the initial link that opened the application.
  //  * @returns url, processing
  //  */

  const useInitialURL = (): any => {
    const [url, setUrl] = useState<string | null>(null);
    const [processing, setProcessing] = useState(true);

    useEffect(() => {
      const getUrlAsync = async (): Promise<any> => {
        // Get the deep link used to open the app
        const initialUrl = await Linking.getInitialURL();
        console.log();

        setUrl(initialUrl);
        setProcessing(false);
      };

      getUrlAsync();
      //getUrlAsync();
      // useEffect(() => {
      //   getUrlAsync();
      // }, [url]);
    }, []);

    return {url, processing};
  };

  // Get the URL used for deep link autocompletion
  const {url, processing} = useInitialURL();

  // console.log(url, processing);

  // // {() => navigateToPage('Request Token')}>
  // const getToken = () => {
  //   console.log('URL is ', url);
  //   const myUrl = url;
  //   if (myUrl == null) {
  //     console.log('Result is  null', -99);
  //     return '';
  //   }
  //   const location = myUrl.search('/reset-token/');
  //   if (location === -1 || !location || location == null) {
  //     console.log('Result is  ', -99);
  //     return '';
  //   }
  //   const entityId = myUrl.substring(location, url.length).split('/');

  //   console.log('Found Result is  ', entityId[2]);
  //   //setLoginToken(entityId[2]);
  //   //navigateToPage('Request Token');
  //   return entityId[2];
  // };

  // const [loginToken, setLoginToken] = useState(getToken());

  // console.log('LoginToken: ' + loginToken);

  if (processing) {
    return <>Loading</>;
  }
  return (
    <>
      <StatusBar barStyle="dark-content" backgroundColor="#0f153a" />
      <NavigationContainer>
        <Stack.Navigator screenOptions={{headerShown: false}}>
          {/* <Stack.Screen name={'Login'} component={LoginScreen} /> */}
          {/* <Stack.Screen name={'Home'} component={HomeScreen} /> */}
          <Stack.Screen
            name="Sign In"
            component={SignIn}
            initialParams={{
              url,
              processing,
            }}
          />
          <Stack.Screen
            name="Sign Up"
            component={SignUp}
            initialParams={{
              url,
              processing,
            }}
          />
          <Stack.Screen
            name="Medical Entities"
            component={MedicalEntities}
            options={{title: 'BioLinked Participant Portal'}}
          />
          <Stack.Screen
            name="Med Questions"
            component={MedQuestions}
            options={{title: 'BioLinked Participant Portal'}}
          />
          <Stack.Screen
            name="Reset Password"
            component={ResetPassword}
            initialParams={{
              url,
              processing,
            }}
          />
          <Stack.Screen
            name="Connect Donor ID"
            component={ConnectDonnorID}
            options={{title: 'BioLinked Participant Portal'}}
            initialParams={{
              url,
              processing,
            }}
          />
          <Stack.Screen
            name="Trouble Signing In"
            component={TroubleSigningIn}
          />
          <Stack.Screen name="Retrieve Username" component={RetrieveUsername} />
          <Stack.Screen name="Request Token" component={RequestToken} />
          <Stack.Screen name="Change Password" component={ChangePassword} />
          <Stack.Screen name="Manage Account" component={ManageAccount} />
          <Stack.Screen name="Update Profile" component={UpdateProfile} />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

const App = () => {
  return (
    <PaperProvider theme={theme}>
      <Nav />
    </PaperProvider>
  );
};

export default App;
