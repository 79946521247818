/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import {Text, StyleSheet, View, TouchableOpacity} from 'react-native';
import {withTheme} from 'react-native-paper';

const CheckBox = (props) => {
  const [isSelected, setSelection] = useState(Boolean(props.value));
  const {colors} = props.theme;

  useEffect(() => {
    setSelection(props.value);
  }, [props.QuestionID]);

  const onSelect = (title: string, value: boolean, QuestionID: string) => {
    setSelection(value);
    props.checkvalue(title, value, QuestionID);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.checkedbox,
          isSelected ? {backgroundColor: colors.accent} : {},
        ]}>
        <Text
          style={styles.text}
          onPress={() => onSelect(props.title, !isSelected, props.QuestionID)}>
          {props.title}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 1,
  },
  checkedbox: {
    backgroundColor: 'transparent',
    width: '100%',
    minWidth: 280,
    maxWidth: 300,
    minHeight: 40,
    maxHeight: 80,
    marginVertical: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 25,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
  },
  text: {
    color: 'white',
  },
});

export default withTheme(CheckBox);
