/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {useState} from 'react';
import {
  View,
  Image,
  Text,
  StyleSheet,
  Platform,
  SafeAreaView,
  ScrollView,
  TouchableOpacity
} from 'react-native';
import {
  TextInput,
  Card,
  Title,
  Button,
  HelperText,
  useTheme,
} from 'react-native-paper';
import {environment} from './../environments/environment.prod';

const ChangePassword = ({navigation, route}) => {
  const {
    colors: {
      accent,
      surface,
    },
  } = useTheme();
  const {token, userProfile} = route.params;
  const [username, SetUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [genericError, setGenericError] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [success, setSuccess] = useState('');

  const resetPassword = async () => {
    if (errorPassword) {
      setGenericError('Password is not valid');
      return;
    }
    if (passwordMatch) {
      setGenericError('Passwords do not match');
      return;
    }
    const json: string =
      '{"UserName": "' +
      username +
      '" , "OldPassword": "' +
      oldPassword +
      '", "NewPassword": "' +
      newPassword +
      '" , "ConfirmPassword": "' +
      secondPassword +
      '"}';
    console.log(json);
    try {
      const response = await fetch(
        environment.ApiURL + '/auth/changepassword',
        {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: json,
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.Success) {
            setGenericError('');
            setSuccess(data.Message);
          } else {
            setSuccess('');
            setGenericError(data.Message);
          }
        })
        .catch((error) => {
          setSuccess('');
          setGenericError(error);
        });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  function validatePassword(password: string) {
    setGenericError('');
    let reg = /^(?=.{7,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
    if (reg.test(password) === true) setErrorPassword('');
    else setErrorPassword('Invalid Password');
    setNewPassword(password);
  }

  function checkMatch(confirmPassowrd: string) {
    setSecondPassword(confirmPassowrd);
    if (confirmPassowrd !== newPassword)
      setPasswordMatch('Passwords do not match');
    else setPasswordMatch('');
  }

  const navigateToPage = (page: string) => {
    navigation.navigate(page, {token, userProfile});
  };

  return (
    <SafeAreaView>  
      <ScrollView>
        <Card style={styles.card}>
          <View style={styles.logo}>
          {Platform.OS === 'web' ? (
          <TouchableOpacity activeOpacity = { .5 } onPress={() => navigateToPage('Medical Entities')}>
          <Image
            style={styles.image}
            source={require('../assets/logo.png')}
          />
          </TouchableOpacity>
            ) : (
              <Image
              style={styles.image}
              source={require('../assets/logo.png')}
            />
            )}
          </View>
            <Card.Content>
              <Title style={styles.title}>Change Password</Title>
            </Card.Content>
            <View style={styles.container}>
              <View>
                <TextInput
                  textAlign="left"
                  mode="outlined"
                  style={styles.inputContainerStyle}
                  theme={{
                    colors: {
                      text: 'white',
                      primary: accent,
                      background: surface,
                    },
                  }}
                  label="Username"
                  value={username}
                  autoCapitalize="none"
                  onChangeText={(text) => SetUsername(text)}
                />
                <TextInput
                  mode="outlined"
                  style={styles.inputContainerStyle}
                  theme={{
                    colors: {
                      text: 'white',
                      primary: accent,
                      background: surface,
                    },
                  }}
                  label="Current Password"
                  value={oldPassword}
                  onChangeText={(text) => setOldPassword(text)}
                  secureTextEntry={true}
                />
                <TextInput
                  mode="outlined"
                  style={styles.inputContainerStyle}
                  theme={{
                    colors: {
                      text: 'white',
                      primary: accent,
                      background: surface,
                    },
                  }}
                  label="New Password"
                  value={newPassword}
                  onChangeText={(text) => validatePassword(text)}
                  secureTextEntry={true}
                />
                <TextInput
                  mode="outlined"
                  style={styles.inputContainerStyle}
                  theme={{
                    colors: {
                      text: 'white',
                      primary: accent,
                      background: surface,
                    },
                  }}
                  label="Confirm Password"
                  value={secondPassword}
                  onChangeText={(text) => checkMatch(text)}
                  secureTextEntry={true}
                  error={genericError.length != 0}
                />
                {genericError.length != 0 && (
                  <HelperText type="error" style={styles.helper} visible={true}>
                    {genericError}
                  </HelperText>
                )}
                {success.length != 0 && (
                  <HelperText type="error" style={styles.success} visible={true}>
                    {success}
                  </HelperText>
                )}
              </View>
              <View>
                <Text
                  style={styles.error}
                  value={genericError}
                  disabled={true}></Text>
              </View>
              <View>
                <Button
                  style={styles.buttonAlt}
                  mode="contained"
                  onPress={() => resetPassword()}>
                  <Text>Confirm</Text>
                </Button>
                <Button
                  style={styles.forgot}
                  mode="contained"
                  onPress={() => navigateToPage('Manage Account')}
                  icon="chevron-left">
                  <Text>Back</Text>
                </Button>
              </View>
              <View>
                <Text
                  style={styles.error}
                  value={genericError}
                  disabled={true}></Text>
              </View>
            </View>
          </Card>
        </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bgcontainer: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
  },
  title: {
    textAlign: 'center',
    marginTop: "5%",
  },
  card: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 30,
    height: 'auto',
    width: "100%",
    minWidth: 350,
    maxWidth: 500,
    margin: 'auto',
    marginTop: 50,
    marginBottom: 30,
    padding: "5%",
  },
  container: {
    padding: 8,
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
  },
  fontSize: {
    fontSize: 24,
  },
  textArea: {
    height: 50,
    marginLeft: 10,
  },
  button: {
    margin: 8,
    padding: 10,
    marginTop: 10,
  },
  buttonAlt: {
    margin: 8,
    padding: 10,
    marginTop: 10,
    backgroundColor: '#f1955b',
  },
  textInput: {
    borderColor: 'transparent',
  },
  forgot: {
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    marginLeft: 15,
    fontWeight: '400',
    textTransform: 'capitalize',
  },
  logo: {
    marginBottom: 10,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    position: 'relative',
    margin: 'auto',
  },
  error: {
    fontSize: 14,
    color: '#FF0000',
    paddingLeft: 25,
    paddingTop: 4,
  },
  success: {
    fontSize: 14,
    color: '#c9deea',
    paddingLeft: 25,
    paddingTop: 4,
  },
  image: {
    marginBottom: 10,
    zIndex: 999999,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 100,
    marginTop: "15%",
  },
});

export default ChangePassword;
