/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  Button,
  Platform,
} from 'react-native';
import {RadioButton, useTheme} from 'react-native-paper';

const RadioButtonss = ({data, selectedOption, onSelect}) => {
  const isButtonChecked = () =>
    selectedOption === data.OptionalValue ? true : false;
  const {
    colors: {background},
  } = useTheme();

  return (
    <View>
      <View key={data.OptionalValue} style={styles.buttonContainer}>
        {isButtonChecked() ? (
          <TouchableOpacity
            style={styles.checkedCircle}
            onPress={() => {
              onSelect(data);
            }}>
            <Text style={{color: 'white'}}>{data.OptionalValue}</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={styles.radio}
            onPress={() => {
              onSelect(data);
            }}>
            <Text style={{color: 'white'}}>{data.OptionalValue}</Text>
          </TouchableOpacity>
        )}

        {/* {selectedOption && selectedOption.OptionalValue === data.OptionalValue && (
              <View style={styles.checkedCircle} />
            )} */}
      </View>
    </View>
  );
};
export default RadioButtonss;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: 5,
  },
  radio: {
    backgroundColor: 'transparent',
    width: '100%',
    minHeight: 40,
    maxHeight: 80,
    minWidth: 280,
    maxWidth: 300,
    marginVertical: 5,
    paddingVertical: 10,
    borderRadius: 25,
    color: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    paddingLeft: 20,
  },
  checkedCircle: {
    backgroundColor: '#f1955b',
    width: '100%',
    minHeight: 40,
    maxHeight: 80,
    minWidth: 280,
    maxWidth: 300,
    marginVertical: 5,
    paddingVertical: 10,
    borderRadius: 25,
    //color: 'white',
    //borderWidth: 1,
    //borderStyle: 'solid',
    //borderColor: 'white',
    paddingLeft: 20,
    // minHeight: 40,
    // maxHeight: 80,
    // width: '101%',
    // minWidth: 280,
    // maxWidth: 300,
    // borderRadius: 25,
    // //position: 'absolute',
    // zIndex: -1,
    ...Platform.select({
      ios: {
        marginTop: -1,
        marginLeft: -1,
      },
      web: {
        //marginRight: 50,
        //marginBottom: 15,
        //marginTop: -11,
        //marginLeft: -22,
      },
    }),
  },
});
