import React from 'react';
import {AppRegistry} from 'react-native';
// import { name as appName } from '../app.json';
import App from './App';

const AppWeb = () => (
  <>
    <style type="text/css">{`
  @font-face {
    font-family: 'MaterialIcons';
    src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
  }
  @font-face {
    font-family: 'MaterialCommunityIcons';
    src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
  }
  @font-face {
    font-family: 'FontAwesome';
    src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
  }
  @font-face {
    font-family: "MyriadProBold";
    src: url(${require('./assets/fonts/MyriadProBold.ttf')}) format('truetype');
  }
`}</style>
    <App />
  </>
);

export default function Main() {
  return <AppWeb />;
}

// AppRegistry.registerComponent('App', () => AppWeb);
AppRegistry.registerComponent('App', () => Main);

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
