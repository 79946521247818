import {Platform} from 'react-native';

export const environment = {
  // DEV
  // ApiURL:
  //   Platform.OS === 'android'
  //     ? 'http://10.0.2.2:5000'
  //     : 'http://localhost:5000',
  // Staging
  // ApiURL: 'https://biolinked-api-staging.azurewebsites.net',
  // DEMO
  // ApiURL: 'https://biolinked-api-demo.azurewebsites.net',
  // PROD
  ApiURL: 'https://biolinked-api.azurewebsites.net',
};
