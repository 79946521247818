/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  View,
  StyleSheet,
  SafeAreaView,
  Image,
  Text,
  Linking,
  TouchableOpacity,
  Platform,
  TouchableHighlight,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  FlatList,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native-gesture-handler';
import {
  Card,
  Paragraph,
  Button,
  Title,
  HelperText,
  Avatar,
  useTheme,
  ProgressBar,
} from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import {environment} from './../environments/environment.prod';
import {Category} from '../models/Index';
import {Icon} from 'react-native-elements';
import {ScreenContainer} from 'react-native-screens';
import {not} from 'react-native-reanimated';

// getting styles
import Styles from './Styles/medicalEntitiesStyle';
import DropDown from 'react-native-paper-dropdown';

const styles = Styles();

const MedicalEntities = ({navigation, route}) => {
  const {token, userProfile, reload} = route.params;
  const [updateStats, setUpdateStats] = useState(route.params?.index?.reload);
  const [updateEntities, setUpdateEntities] = useState(
    route.params?.index?.reload,
  );
  const [isLoading, setLoading] = useState(true); //loading indicator when signin
  const [isLoadingPS, setLoadingPS] = useState(true); //loading indicator for progress stats
  const [isLoadingNotifications, setLoadingNotifications] = useState(true); //
  const [data, setData] = useState([]);
  const [progressStats, setProgressStats] = useState([]); //state for progress statistics of all medical entities
  const [newsLetter, setNewsLetter] = useState(false);
  const [successNL, setSuccessNL] = useState('');
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [myAccountOpen, setMyAccountOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [changedNotifications, setChangedNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);

  const [organizations, setOrganizations] = useState('');

  const [researchOpportunities, setResearchOpportunities] = useState([]);
  //const logoURL: string = `https://biolinked.blob.core.windows.net/partner-logos/`;
  const [logo, setLogo] = useState('');

  const [isResearch, setIsResearch] = useState(false);

  const faqURL = 'https://biolinked.org/faq';
  const tosURL = 'https://biolinked.org/terms';

  // const imageDomain = 'https://biolinked.blob.core.windows.net/entities/';
  const {
    colors: {primary},
  } = useTheme();

  const gotoFAQ = () => {
    if (Platform.OS === 'web') {
      window.open(faqURL, '_blank');
    } else {
      Linking.canOpenURL(faqURL).then((supported) => {
        return Linking.openURL(faqURL);
      });
    }
  };

  const gotoTOS = () => {
    if (Platform.OS === 'web') {
      window.open(tosURL, '_blank');
    } else {
      Linking.canOpenURL(tosURL).then((supported) => {
        return Linking.openURL(tosURL);
      });
    }
  };

  const researchToggle = () => {
    setIsResearch(!isResearch);
  };

  const navigateToPage = (page: string, category: Category) => {
    setUpdateStats(undefined);
    console.log('categoryId from medical', category);
    navigation.navigate(page, {category, token, userProfile});
  };

  const getStorage = async () => {
    // console.log('Storage - get()');
    try {
      const value = await AsyncStorage.getItem('@NewsLetter');
      if (value != null) {
        // console.log(value);
        setNewsLetter(JSON.parse(value));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const setStorage = async () => {
    console.log('Storage - set()');
    try {
      // if (fName != '' && eMail != '') {
      await AsyncStorage.setItem('@NewsLetter', 'true');
      setSuccessNL('You are subscribed.');
      // }
    } catch (error) {
      // console.log(error)
    }
  };

  // Counts unread notifications
  const updateNotificationsCount = (): void => {
    const temp = notifications.filter(
      (notification) => !notification.read,
    ).length;
    setNotificationsCount(temp);
  };

  const getMedicalEntities = async () => {
    try {
      const response = await fetch(
        environment.ApiURL +
          `/ParticipantPortal/getMedicalEntities?UserId=${userProfile.UserId}`,
      );
      const json = await response.json();
      setData(json);
      console.log('getMedicalEntities', json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateMedicalEntities = () => {
    if (
      route.params?.index?.reload != undefined &&
      updateEntities == undefined
    ) {
      setUpdateEntities(route.params?.index?.reload);
    }
    if (updateEntities) {
      getMedicalEntities();
      setUpdateEntities(false);
    }
    return <view />;
  };

  const updateProgressStats = () => {
    if (route.params?.index?.reload != undefined && updateStats == undefined) {
      setUpdateStats(route.params?.index?.reload);
    }
    if (updateStats) {
      // getProgressStats();
      setUpdateStats(false);
    }
    // return (<View></View>)
  };

  const getProgressStats = async () => {
    try {
      const response = await fetch(
        environment?.ApiURL +
          `/ParticipantPortal/getProgressStats?UserId=${userProfile.UserId}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      const json = await response.json();
      setProgressStats(json);
      // console.log('progress stats', json);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPS(false);
    }
  };

  // Makes API call to get user notifications
  const getNotifications = async (): Promise<void> => {
    try {
      console.log('');
      const response = await fetch(
        environment.ApiURL +
          `/Notifications/GetUserNotificationsByUserId?id=${userProfile.UserId}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      const json = await response.json();
      setNotifications(json);

      //console.log('Notifications data: ', json);
    } catch (error) {
      console.error('Getting notifications error: ', error);
    } finally {
      setLoadingNotifications(false);
    }
  };

  // Change notifications if they are changed
  const notificationsStatusUpdate = async (): Promise<void> => {
    const read = [];
    const unread = [];

    for (let i = 0; i < changedNotifications.length; i++) {
      if (changedNotifications[i].read) {
        read.push(changedNotifications[i].id);
      } else {
        unread.push(changedNotifications[i].id);
      }
    }

    if (read.length > 0) {
      try {
        const response = await fetch(
          environment.ApiURL + '/Notifications/updateRead',
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify(read),
          },
        );
      } catch (error) {
        console.error(error);
      } finally {
        updateNotificationsCount();
      }
    }
    if (unread.length > 0) {
      try {
        const response = await fetch(
          environment.ApiURL + '/Notifications/updateUnread',
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify(unread),
          },
        );
      } catch (error) {
        console.error(error);
      } finally {
        updateNotificationsCount();
      }
    }

    setChangedNotifications([]);
  };
  const getOrganizations = async (): Promise<void> => {
    try {
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/getOrganizations',
      );
      const json = await response.json();
      console.log('json: ', json);

      const index = json.findIndex(
        (item) => item.EntityId === userProfile.EntityIdentity,
      );

      setOrganizations(json[index].Name);
      setLogo(json[index].WebsiteURL);
      //setorganizations(json);
    } catch (error) {
      console.error(error);
    } finally {
      console.log('organizations: ', organizations);
    }
  };

  const getResearchOpportunities = async (): Promise<void> => {
    try {
      const response = await fetch(
        environment.ApiURL +
          '/ParticipantPortal/getResearchOpportunities?EID=' +
          userProfile.EntityIdentity,
      );
      const json = await response.json();
      console.log('json: ', json);

      setResearchOpportunities(json);
      //setorganizations(json);
    } catch (error) {
      console.error(error);
    } finally {
      console.log(' Research Opportunities: ', researchOpportunities);
      console.log(' Research Opportunities ID: ', userProfile.EntityIdentity);
      console.log(
        ' Research Opportunities URL: ',
        environment.ApiURL +
          '/ParticipantPortal/getResearchOpportunities?EID=' +
          userProfile.EntityIdentity,
      );
    }
  };

  useEffect(() => {
    //console.log('here');
    getNotifications();
    getMedicalEntities();
    getProgressStats();
    getStorage();
    getOrganizations();
    getResearchOpportunities();
  }, []);

  useEffect(() => {
    // updateProgressStats();
    getProgressStats();
  }, [updateStats]);

  // Updates notification count on notification changes
  useEffect(() => {
    updateNotificationsCount();
  }, [notificationsCount, notifications]);

  const Item = ({title, description, time, id, read}) => (
    <Card elevation={5} style={styles.notificationsItemCard}>
      <Card.Title
        titleStyle={styles.notificationsItemTitle}
        title={title}
        rightStyle={styles.notificationsItemRight}
        right={() => (
          <>
            <View>
              {read ? (
                <Icon
                  accessibilityLabel="read button"
                  accessible={true}
                  name="mail-outline"
                  color="rgba(255, 255, 255, 0.87)"
                  onPress={(): void => {
                    console.log('Notifications unread clicked');
                    // Checks if the notification is already in changes
                    if (!changedNotifications.find((x) => x.id === id)) {
                      // Changing notifications value
                      const temp = [...notifications];
                      temp.find((x) => x.id === id).read = false;
                      setNotifications(temp);
                      setChangedNotifications([
                        notifications.find((x) => x.id === id),
                        ...changedNotifications,
                      ]);
                    } else {
                      // Change notification value
                      const temp1 = [...notifications];
                      temp1.find((x) => x.id === id).read = false;
                      setNotifications(temp1);

                      // Change changedNotification value
                      const temp2 = [...changedNotifications];
                      temp2.find((x) => x.id === id).read = false;
                      setChangedNotifications(temp2);
                    }
                  }}
                />
              ) : (
                <Icon
                  accessibilityLabel="unread buttton"
                  accessible={true}
                  name="mail"
                  color="rgba(255, 255, 255, 0.87)"
                  onPress={(): void => {
                    console.log('Notifications read clicked');

                    // Checks if the notification is already in changes
                    if (!changedNotifications.find((x) => x.id === id)) {
                      // Changing notifications value
                      const temp = [...notifications];
                      temp.find((x) => x.id === id).read = true;
                      setNotifications(temp);
                      setChangedNotifications([
                        notifications.find((x) => x.id === id),
                        ...changedNotifications,
                      ]);
                    } else {
                      // Change notification value
                      const temp1 = [...notifications];
                      temp1.find((x) => x.id === id).read = true;
                      setNotifications(temp1);

                      // Change changedNotification value
                      const temp2 = [...changedNotifications];
                      temp2.find((x) => x.id === id).read = true;
                      setChangedNotifications(temp2);
                    }
                  }}
                />
              )}
            </View>
          </>
        )}
      />
      <Card.Content style={styles.notificationsItemContent}>
        <Paragraph style={styles.notificationsItemContentDescription}>
          {description}
        </Paragraph>
        <Text style={styles.notificationsItemContentDate}>
          {new Date(time).toDateString()}
        </Text>
      </Card.Content>
    </Card>
  );

  // const getUserOrginization = () => {
  //   //userProfile.EntityIdentity
  //   const index = organizations.findIndex(item => item.EntityId === userProfile.EntityIdentity);

  //   return organizations[index].Name;
  // }

  const renderItem = ({item}) => (
    <Item
      title={item.title}
      description={item.description}
      time={item.time}
      read={item.read}
      id={item.id}
    />
  );

  /**
   * Displays medical questions in cards
   * @returns Component that has the medical questions
   */
  const MedicalQuestionsNavigation = () => {
    return (
      <>
        <Text style={styles.entityTitle}>My Questionnaire</Text>
        <View>
          <View style={styles.entitySubTitle}>
            <View style={styles.logoContainer}>
              <TouchableHighlight
                style={styles.logoImage}
                onPress={() => {
                  const urlType = logo?.split('/');
                  if (urlType[0] != '') {
                    if (urlType[0] == 'https:' || urlType[0] == 'http:') {
                      window.open(logo, '_blank');
                    } else {
                      window.open('https://' + logo, '_blank');
                    }
                  } else {
                    alert(
                      'There is no website listed for this organization at this time.',
                    );
                  }
                }}>
                <Image
                  style={styles.logoImage}
                  source={{
                    uri:
                      'https://biolinked.blob.core.windows.net/partner-logos/' +
                      userProfile.EntityIdentity +
                      '.png',
                  }}
                />
              </TouchableHighlight>
            </View>
          </View>
        </View>
        <View style={styles.background}>
          <View style={styles.category}>
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              data.map((question, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => navigateToPage('Med Questions', question)}
                    style={styles.surface}>
                    <View style={styles.medicalEntitiesButtonContainer}>
                      <Text style={styles.entity}>{question.EntityName}</Text>
                      <View style={styles.medicalEntitiesImagesContainer}>
                        <Image
                          style={styles.medicalentitiesimages}
                          aria-label={
                            'Medical Question clickable Image for' +
                            question.EntityName
                          }
                          source={{
                            uri:
                              'https://biolinked.blob.core.windows.net/entities/' +
                              question.Id +
                              '.png',
                          }}
                        />
                      </View>
                    </View>
                    <View style={styles.progress}>
                      {isLoadingPS ? (
                        <ProgressBar
                          aria-label={
                            'Medical Question Progress for ' +
                            question.EntityName
                          }
                          aria-labelledby="labeldiv"
                          style={styles.progress}
                          progress={0}
                          color={'#f1955b'}
                        />
                      ) : (
                        <ProgressBar
                          style={styles.progress}
                          aria-label={
                            'Medical Question Progress for ' +
                            question.EntityName
                          }
                          progress={progressStats
                            .filter((i) => i.EntityName === question.EntityName)
                            .map((j) => j.AnswerCount / j.QuestionCount)
                            .reduce((k) => k)}
                          color={'#f1955b'}
                        />
                      )}
                    </View>
                  </TouchableOpacity>
                );
              })
            )}
          </View>
        </View>
        <Title style={styles.connectFooter}>
          Connect together. Cure together.
        </Title>
      </>
    );
  };

  // TODO: Handle empty image for participant
  // const ResearchImage = (url) => {
  //   console.log('Research Image: ', url);
  //   if (url == undefined || url == '' || url == null) {
  //     // null condition
  //     console.log('TRUE ');
  //     return (
  //       <Image
  //         style={styles.researchImages}
  //         source={require('../assets/logo.png')}
  //       />
  //     );
  //   } else {
  //     console.log('FALSE ');
  //     return (
  //       <Image
  //         style={styles.researchImages}
  //         source={{
  //           uri: url,
  //         }}
  //       />
  //     );
  //   }
  // };

  /**
   * Takes click infor and sends it to the API
   * @param url URL the user clicked
   * @param pId Project ID of the research
   */
  const userClick = async (url, pId) => {
    console.log('userClick: ', url);
    try {
      const dataToSend = {
        UserId: userProfile.UserId,
        PId: pId,
        EntityID: userProfile.EntityIdentity,
        Url: url,
      };
      const json: string =
        '{"PId": "' +
        pId +
        '", "EntityId": "' +
        userProfile.EntityIdentity +
        '", "Url": "' +
        url +
        '", "UserId": ' +
        userProfile.UserId +
        ', "}';
      console.log('userClick: ', dataToSend);
      console.log('userClick: ', JSON.stringify(dataToSend));
      const response = await fetch(
        environment.ApiURL + '/ParticipantPortal/researchOpportunitiesClick',
        {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          //mode: 'cors',
          body: JSON.stringify(dataToSend),
        },
      );

      // const response = await fetch(
      //   environment.ApiURL + '/ParticipantPortal/researchOpportunitiesClick',
      //   {
      //     method: 'POST',
      //     headers: {
      //       Authorization: 'Bearer ' + token,
      //       'Content-Type': 'application/json',
      //       Accept: 'application/json',
      //     },
      //     body: json, //JSON.stringify(dataToSend),
      //   },
      // )
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log(data);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     //setGenericError(error);
      //   });
      //const content = await response.json();
      //console.log('Content: ', content);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Displays image for the research opportunity
   * @returns Component that is is the apporprate image for the project
   */
  const ResearchImage = (props) => {
    return props?.imageUrl.length > 0 ? (
      <>
        <View style={styles.researchImagesContainer}>
          {/* <ResearchImage url={data.ImageUrl} /> */}
          {/* <Image
                        style={styles.researchImages}
                        source={require('../assets/bioLinkedIcon-WhiteDropNew.png')}
                      /> */}
          <Image
            style={styles.researchImages}
            source={{
              uri: props?.imageUrl,
            }}
          />
        </View>
      </>
    ) : (
      <>
        <View style={styles.researchImagesContainer}>
          {/* <ResearchImage url={data.ImageUrl} /> */}
          {/* <Image
                        style={styles.researchImages}
                        source={require('../assets/bioLinkedIcon-WhiteDropNew.png')}
                      /> */}
          <Image
            style={styles.researchImages}
            source={require('../assets/bioLinkedIcon-WhiteDropNew.png')}
          />
        </View>
      </>
    );
  };

  /**
   * Displays research in cards
   * @returns Component that has the research cards
   */
  const ResearchNavigation = () => {
    return (
      <>
        <Text style={styles.entityTitle}>Research Opportunities</Text>
        <View>
          <View style={styles.entitySubTitle}>
            <View style={styles.logoContainer}>
              <TouchableHighlight
                style={styles.logoImage}
                onPress={() => {
                  const urlType = logo?.split('/');
                  if (urlType[0] != '') {
                    if (urlType[0] == 'https:' || urlType[0] == 'http:') {
                      window.open(logo, '_blank');
                    } else {
                      window.open('https://' + logo, '_blank');
                    }
                  } else {
                    alert(
                      'There is no website listed for this organization at this time.',
                    );
                  }
                }}>
                <Image
                  style={styles.logoImage}
                  source={{
                    uri:
                      'https://biolinked.blob.core.windows.net/partner-logos/' +
                      userProfile.EntityIdentity +
                      '.png',
                  }}
                />
              </TouchableHighlight>
            </View>
          </View>
        </View>
        <View style={styles.backgroundAlt}>
          <View style={styles.category}>
            {isLoading ? (
              <ActivityIndicator />
            ) : researchOpportunities.length > 0 ? (
              researchOpportunities.map((research, index) => {
                //console.log('Research Data: ', data);
                return (
                  <div>
                    <View
                      style={styles.surfaceAlt}
                      onFocus={(e) => {
                        console.log('Focus:: ', e);
                      }}>
                      <ResearchImage imageUrl={research.ImageUrl} />
                      <View style={styles.OpportunityTitleContainer}>
                        <Text style={styles.titleAlt2}>{research.Title}</Text>
                      </View>

                      <View style={styles.opportunityDescContainer}>
                        <Text numberOfLines={10} style={styles.descriptionAlt}>
                          {research.Description}
                        </Text>
                      </View>
                      <View style={styles.opportunityButtonContainer}>
                        <Button
                          style={styles.opportunityButton}
                          mode="contained"
                          onPress={() => {
                            userClick(research.Url, research.PId);
                            const urlType = research.Url.split('/');
                            if (
                              urlType[0] == 'https:' ||
                              urlType[0] == 'http:'
                            ) {
                              window.open(research.Url, '_blank');
                            } else {
                              window.open('https://' + research.Url, '_blank');
                            }
                          }}>
                          <Text>Learn More</Text>
                        </Button>
                      </View>
                    </View>
                  </div>
                );
              })
            ) : (
              <View style={styles.surfaceAlt2}>
                <Text style={styles.entityTitle2}>
                  There are currently no research opportunities
                </Text>
                <Text style={styles.entityTitle2}>
                  Please check back at another time.
                </Text>
              </View>
            )}
          </View>
        </View>
        <Title style={styles.connectFooter}>
          Connect together. Cure together.
        </Title>
      </>
    );
  };

  /**
   * Switch between questions and research
   */
  const ResearchToggleButton = () => {
    if (isResearch) {
      return (
        <Button
          style={styles.buttonAlt}
          mode="contained"
          onPress={() => researchToggle()}>
          <Text>My Questionnaire</Text>
        </Button>
      );
    } else {
      return (
        <Button
          style={styles.buttonAlt}
          mode="contained"
          onPress={() => researchToggle()}>
          <Text>Research Opportunities</Text>
        </Button>
      );
    }
  };

  const removeCookie = (name) => {
    // Set the expiration date to a past date
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  };

  const QuestionnaireOrResearchInfo = () => {
    if (isResearch) {
      return <ResearchNavigation />;
    } else {
      return <MedicalQuestionsNavigation />;
    }
  };

  return (
    <SafeAreaView>
      <ScrollView>
        {notificationsOpen && (
          <View style={styles.notificationsPanel}>
            <Card elevation={5} style={styles.notificationsPanelCard}>
              <Card.Title
                titleStyle={styles.notificationsPanelTitle}
                title="Notifications"
              />
              <Card.Content>
                {notifications.length > 0 ? (
                  <FlatList
                    data={notifications}
                    renderItem={renderItem}
                    keyExtractor={(item) => {
                      return item.id;
                    }}
                  />
                ) : (
                  <Card elevation={5} style={styles.notificationsPanelEmpty}>
                    <Card.Title
                      style={styles.notificationsPanelEmptyTitle}
                      titleStyle={styles.notificationsPanelEmptyTitleText}
                      title={'No notifications at the time'}
                    />
                  </Card>
                )}
              </Card.Content>
            </Card>
          </View>
        )}
        {/* Create My Account panel */}
        {myAccountOpen && (
          <View
            style={{
              zIndex: 100,
              position: 'absolute',
              maxHeight: '40%',
              maxWidth: '90%',
              borderRadius: 10,
              //overflowY: 'scroll',
              overflowX: 'hidden',
              right: 10,
              top: 50,
              boxShadow:
                '0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)',
            }}>
            <Card
              elevation={5}
              style={{
                borderRadius: 5,
                borderWidth: 1,
                backgroundColor: '#1c244b',
              }}
              onPress={() =>
                navigation.navigate('Manage Account', {token, userProfile})
              }>
              <Card.Title
                style={
                  {
                    //margin: 39,
                  }
                }
                titleStyle={styles.notificationsPanelEmptyTitleText}
                title={'Manage Account'}
              />
            </Card>
            <Card
              elevation={5}
              style={{
                borderRadius: 5,
                borderWidth: 1,
                backgroundColor: '#1c244b',
              }}
              onPress={() => {
                removeCookie('bioSPInfo');
                navigation.navigate('Sign In');
              }}>
              <Card.Title
                style={
                  {
                    //margin: 39,
                  }
                }
                titleStyle={styles.notificationsPanelEmptyTitleText}
                title={'Sign Out'}
              />
            </Card>
          </View>
        )}
        {/*Listener for click outside notification panel */}
        <View
          onStartShouldSetResponder={() => {
            if (notificationsOpen) {
              setNotificationsOpen(false);
              setMyAccountOpen(false);
              notificationsStatusUpdate();
            }
            return false;
          }}>
          <View>
            {updateProgressStats()}
            {updateMedicalEntities()}

            <Card style={styles.card}>
              <ScreenContainer style={styles.navbar}>
                <View style={styles.notificationIconNavbar}>
                  <Icon
                    accessibilityLabel="Notifications button"
                    accessible={true}
                    size={30}
                    name="notifications"
                    color="rgba(255, 255, 255, 0.87)"
                    onPress={(): void => {
                      if (notificationsOpen) {
                        notificationsStatusUpdate();
                      }
                      setNotificationsOpen(!notificationsOpen);
                      setMyAccountOpen(false);

                      //getNotifications();
                    }}
                  />
                  {notificationsCount > 0 && (
                    <View
                      pointerEvents="none"
                      style={styles.notificationCircle}>
                      <Text style={styles.notificationCount}>
                        {notificationsCount}
                      </Text>
                    </View>
                  )}
                </View>

                {/* <Button
                  style={styles.navbarContent}
                  mode="contained"
                  onPress={() => navigation.navigate('Sign In')}>
                  <Card>
                    <Text>Log Out</Text>
                  </Card>
                </Button> */}
                <Button
                  style={styles.navbarContent}
                  mode="contained"
                  onPress={(): void => {
                    setMyAccountOpen(!myAccountOpen);
                    setNotificationsOpen(false);

                    //getNotifications();
                  }}>
                  <Card>
                    <Text>My Account</Text>
                  </Card>
                </Button>
              </ScreenContainer>

              <View
                style={{
                  //position: 'absolute',
                  marginBottom: 10,
                  zIndex: 999999,
                  alignItems: 'flex-start',
                  width: 375,
                  height: 150,
                }}>
                <Card>
                  <Image
                    style={{
                      marginBottom: 10,
                      zIndex: 999999,
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 375,
                      height: 150,
                    }}
                    source={require('../assets/logo.png')}
                  />
                </Card>
                {/* <Card>
                  <Image
                    style={styles.orgLogo}
                    source={{uri: logoURL + logo}}
                  />
                </Card> */}
              </View>

              <View
                style={{
                  margin: 0,
                  height: 12,
                  backgroundColor: '#f1955b',
                }}
              />
            </Card>
          </View>
          <View>
            <Card
              style={styles.header}
              // style={{
              //   //flex: 1,
              //   //flexFlow: 'column',
              //   justifyContent: 'center',
              //   //flexWrap: 'warp',
              //   maxHeight: '100%',
              //   width: '100%',
              //   margin: 'auto',
              //   paddingTop: 10,
              //   borderRadius: 0,
              //   backgroundColor: '#c9deea',
              // }}
            >
              <Title style={styles.title}>Welcome to the</Title>
              <Title style={styles.titleAlt}>
                Participant Portal, {userProfile.FirstName}!
              </Title>
              <Paragraph style={styles.paragraph}>
                You are part of a community on a mission to find cures and save
                lives.{'\n'}
                Your referring organization is{' '}
                <Text
                  onPress={() => {
                    const urlType = logo?.split('/');
                    console.log('urlType: ', urlType);
                    if (urlType[0] != '') {
                      if (urlType[0] == 'https:' || urlType[0] == 'http:') {
                        window.open(logo, '_blank');
                      } else {
                        window.open('https://' + logo, '_blank');
                      }
                    } else {
                      alert(
                        'There is no website listed for this organization at this time.',
                      );
                    }
                  }}
                  style={styles.underline}>
                  {organizations}
                </Text>
                .
              </Paragraph>
              <View
                style={{
                  position: 'absolute',
                  right: 30,
                  bottom: 0,
                  height: 100,
                  width: 117,
                }}>
                <Image
                  style={styles.logoImage}
                  source={require('../assets/BL-people.png')}
                />
              </View>
              <View
                style={styles.align}
                // style={{
                //   // display: 'flex',
                //   flexFlow: 'row',
                //   flexWrap: 'wrap',
                //   // display: 'flex',
                //   margin: 'auto',
                //   marginBottom: 5,
                //   paddingHorizontal: 40,
                // }}
              >
                {/* <Button
                  style={styles.buttonAlt}
                  mode="contained"
                  onPress={() =>
                    navigation.navigate('Manage Account', {token, userProfile})
                  }>
                  <Text>Manage Account</Text>
                </Button> */}
                <ResearchToggleButton />
                <Button
                  style={styles.buttonAlt}
                  mode="contained"
                  onPress={() => gotoFAQ()}>
                  <Text>FAQ's</Text>
                </Button>
              </View>
            </Card>
          </View>
          <View>
            <View style={styles.line3} />
            <Card style={styles.cardAlt}>
              <View style={styles.container}>
                <View style={styles.background}>
                  <QuestionnaireOrResearchInfo />
                </View>
              </View>
            </Card>
          </View>
          <View style={styles.newsletter}>
            {newsLetter == false && (
              <View style={styles.titleNL}>
                <Title style={styles.titleNL}>
                  Sign up for the BioLinked Newsletter
                </Title>
                <View style={styles.signup}>
                  <Button
                    style={styles.buttonNL}
                    mode="contained"
                    onPress={() => setStorage()}>
                    <Text>Join</Text>
                  </Button>
                </View>
                {successNL.length != 0 && (
                  <HelperText
                    type="error"
                    style={styles.success}
                    visible={true}>
                    {successNL}
                  </HelperText>
                )}
              </View>
            )}
            {newsLetter == true && (
              <View style={styles.titleNL}>
                <Title style={styles.titleNL}>
                  Thanks for Signing up the BioLinked Newsletter!
                </Title>
              </View>
            )}
            <View>
              <Paragraph style={styles.paragraphAlt}>
                Follow us on social media to be the first to know when we are
                making change.
              </Paragraph>
              <View style={styles.signup}>
                {/* <a href="https://instagram.com/getbiolinked">
            <Avatar.Icon color={'#0f153a'} icon="facebook"></Avatar.Icon>
            </a> */}
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web'
                      ? window.open(
                          'https://www.instagram.com/getbiolinked/',
                          '_blank',
                        )
                      : Linking.openURL(
                          'https://www.instagram.com/getbiolinked/',
                        )
                  }>
                  <Avatar.Icon color={'#0f153a'} icon="instagram" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web'
                      ? window.open(
                          'https://www.facebook.com/GetBioLinked/',
                          '_blank',
                        )
                      : Linking.openURL(
                          'https://www.facebook.com/GetBioLinked/',
                        )
                  }>
                  <Avatar.Icon color={'#0f153a'} icon="facebook" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web'
                      ? window.open(
                          'https://twitter.com/getbiolinked',
                          '_blank',
                        )
                      : Linking.openURL('https://twitter.com/getbiolinked')
                  }>
                  <Avatar.Icon color={'#0f153a'} icon="twitter" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web'
                      ? window.open(
                          'https://www.linkedin.com/company/getbiolinked/',
                          '_blank',
                        )
                      : Linking.openURL(
                          'https://www.linkedin.com/company/getbiolinked/',
                        )
                  }>
                  <Avatar.Icon color={'#0f153a'} icon="linkedin" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web'
                      ? window.open(
                          'https://www.youtube.com/watch?v=QNeQ-gZ6hyA',
                          '_blank',
                        )
                      : Linking.openURL(
                          'https://www.youtube.com/watch?v=QNeQ-gZ6hyA',
                        )
                  }>
                  <Avatar.Icon color={'#0f153a'} icon="youtube" />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    Platform.OS === 'web'
                      ? window.open('mailto:support@biolinked.org', '_blank')
                      : Linking.openURL('mailto:support@biolinked.org')
                  }>
                  <Avatar.Icon color={'#0f153a'} icon="email" />
                </TouchableOpacity>
                {/* <Avatar.Icon color={'#0f153a'} icon="instagram"></Avatar.Icon>
              <Avatar.Icon color={'#0f153a'} icon="facebook"></Avatar.Icon>
              <Avatar.Icon color={'#0f153a'} icon="twitter"></Avatar.Icon>
              <Avatar.Icon color={'#0f153a'} icon="linkedin"></Avatar.Icon>
              <Avatar.Icon color={'#0f153a'} icon="youtube"></Avatar.Icon>
              <Avatar.Icon color={'#0f153a'} icon="email"></Avatar.Icon> */}
              </View>
              <Title style={styles.titleNL}>@getBioLinked</Title>
              <Text style={styles.privacyPolicy} onPress={() => gotoTOS()}>
                {' '}
                Privacy Policy
              </Text>
            </View>
          </View>
          <View style={styles.line2} />
          <View style={styles.footer}>
            <View style={styles.tagline}>
              <Title style={styles.titleAlt}>
                Connect together. Cure together.
              </Title>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default MedicalEntities;
//checkbranch
